body.complete {
    header#navigation {
        position: absolute;
    }

    section#main {
        align-items: normal;
        padding: $navigation_height + 50px;
        display: block;

        .navigation-box {
            &.box-left {
                text-align: left;
            }

            svg {
                g,
                circle,
                polyline {
                    opacity: 1;
                    transition: 200ms all ease;
                }
            }

            &:hover {
                svg {
                    circle {
                        fill: $black;
                        stroke: $black;
                        transition: 200ms all ease;
                    }

                    polyline {
                        stroke: $white;
                        transition: 200ms all ease;
                    }
                }
            }
        }

        button,
        a {
            min-width: 200px;
            padding: 21px 30px;
            border-radius: 33px;
        }

        .top-section {
            margin-top: 60px;

            .content {
                padding-left: 85px;

                @media screen and (max-width: 1050px) {
                    padding-left: 10px;
                }

                h1 {
                    font-size: 40px;
                    margin-top: 20px;
                    padding-bottom: 0px;
                }

                h3 {
                    font-size: 30px;
                }
            }

            .dots {
                svg {
                    position: absolute;
                    bottom: 10px;
                    left: -120px;
                    z-index: -1;
                }
            }

            .download-top {
                svg {
                    margin-bottom: 20px;
                }

                button,
                a {
                    left: 50%;
                    bottom: -20px;
                    position: absolute;
                    transform: translateX(-50%);
                }
            }
        }

        .results {
            margin-top: 150px;

            .result {
                border-bottom: 2px solid $blue;
                padding-bottom: 0px;
                margin-bottom: 100px;

                .title {
                    padding-left: 95px;

                    @media screen and (max-width: 1050px) {
                        padding-left: 25px;
                    }

                    h2 {
                        font-size: 40px;
                    }
                }

                .text {
                    font-size: 18px;
                    padding-top: 30px;
                }

                .score {
                    padding-left: 30px;

                    span {
                        font-size: 30px;
                        border-radius: 50%;
                        border: 2px solid $black !important;
                        padding: 26px 0px;
                        text-align: center;
                        font-weight: bold;
                        width: 75px;
                        height: 75px;
                        display: block;
                    }
                }
            }
        }

        .final-notes {
            margin-bottom: 100px;
            position: relative;

            .content {
                padding-left: 95px;
                padding-right: 200px;

                @media screen and (max-width: 1050px) {
                    padding-left: 10px;
                    padding-right: 100px;
                }
            }

            .download-bottom {
                button,
                a {
                    left: 50%;
                    bottom: 0px;
                    position: absolute;
                    transform: translateX(-50%);
                }
            }

            .dots {
                svg {
                    position: absolute;
                    left: -250px;
                    bottom: -80px;
                    z-index: -1;
                }
            }
        }

        .thank-you {
            h2 {
                font-size: 40px;
            }
        }

        .mountain-complete {
            position: relative;

            img {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .final-links {
            width: 1400px;
            margin-top: 550px;

            .column {
                padding: 0px 20px;

                @media screen and (max-width: 1050px) {
                    width: 100% !important;
                }

                .inner {
                    background: $white;
                    box-shadow: 15px 15px 0px $black;
                    border: 2px solid $black;
                    padding: 40px 50px 40px;
                    width: 100%;
                    text-align: center;
                }

                h3 {
                    font-size: 24px;
                    margin-bottom: 4px;
                }

                p {
                    font-size: 18px;

                    strong {
                        display: inline-block;
                    }
                }

                .arrow {
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    padding-right: 70px;

                    svg {
                        position: absolute;
                        top: 0px;
                        right: -20px;
                        transition: 200ms all ease;
                        transform: rotate(-180deg);

                        g,
                        circle,
                        polyline {
                            opacity: 1;
                            transition: 200ms all ease;
                        }
                    }

                    &:hover {
                        svg {
                            circle {
                                fill: $black;
                                stroke: $black;
                                transition: 200ms all ease;
                            }

                            polyline {
                                stroke: $white;
                                transition: 200ms all ease;
                            }
                        }
                    }
                }
            }
        }

        .pages {
            position: absolute;
            left: -999em;
            top: -999em;
        }
    }
}
