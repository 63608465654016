$green: #53BE4D;
$lightgreen: #32AF84;
$blue: #0097DC;
$yellow: #F5C301;
$orange: #E57E23;
$red: #C31A48;

$white: #fff;
$black: #2D3844;
$lightblue: #F5FBFE;

$navigation_grey: #878D94;

$colors: ('white': $white,
    'black': $black,
    'green': $green,
    'lightgreen': $lightgreen,
    'blue': $blue,
    'lightblue': $lightblue,
    'yellow': $yellow,
    'orange': $orange,
    'red': $red);

$navigation_height: 75px;


$lato: 'Lato',
sans-serif !important;


@each $color,
$value in $colors {

    .ui.popup.#{$color} {

        border-color: $value !important;

        &:before {
            box-shadow: 2px 2px 0px 0px $value !important;
        }
    }

    .color-#{$color} {
        border-color: $value !important;

        *:not(textarea) {
            border-color: $value !important;
        }

        .boxed {
            &:after {
                background: $value !important;
            }
        }

        .loader-animation {
            svg {
                path[fill="rgb(0,151,220)"] {
                    fill: $value !important;
                }
            }
        }

        .navigation-box {
            html.no-touch & {
                &:hover {
                    svg {
                        circle {
                            fill: $value !important;
                            stroke: $value !important;
                        }
                    }
                }
            }

            &.filled {
                svg {
                    circle {
                        fill: $value !important;
                        stroke: $value !important;
                    }
                }
            }
        }

        .sidebar-back {
            background-color: rgba($value, .05) !important;
        }

        &.background-drop {
            .square {
                background-color: rgba($value, .05) !important;
            }
        }

        .dash {
            &:after {
                background: $value !important;
            }
        }

        #menu ul li.active a {
            background: $value !important;
        }

        .bottom ul li {

            html.no-touch & {
                &:hover {
                    .ui.grid {
                        background: $value !important;

                        .number {
                            border-color: $white !important;
                        }

                        .dash:after {
                            background: $white !important;
                        }
                    }
                }
            }

            &.active {
                .ui.grid {
                    background: $value !important;

                    .number {
                        border-color: $white !important;
                    }

                    .dash:after {
                        background: $white !important;
                    }
                }
            }
        }

        &.item.active {
            .progress {
                background-color: rgba($value, .1) !important;

                .bar {
                    background-color: $value !important;
                }
            }
        }

        .ui.basic.button {
            color: $value !important;

            &:hover {
                background-color: $value !important;
                color: $white !important;
            }
        }

        textarea {
            border-bottom-color: $value !important;
        }

        .textarea.done {
            textarea {
                border-color: $value !important;
            }

            .ui.basic.button {
                color: $value !important;

                &:hover {
                    background-color: $value !important;
                    color: $value !important;
                }
            }

            &:after {
                background: $value !important;
            }
        }

        .clear {
            border-color: $white !important;

            &:hover {
                border-color: $value !important;
            }

            svg {
                polygon {
                    fill: $value;
                }
            }
        }
    }
}