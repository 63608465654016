@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700.css);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700.css);
.ui.popup.white {
  border-color: #fff !important; }
  .ui.popup.white:before {
    box-shadow: 2px 2px 0px 0px #fff !important; }

.color-white {
  border-color: #fff !important; }
  .color-white *:not(textarea) {
    border-color: #fff !important; }
  .color-white .boxed:after {
    background: #fff !important; }
  .color-white .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #fff !important; }
  html.no-touch .color-white .navigation-box:hover svg circle {
    fill: #fff !important;
    stroke: #fff !important; }
  .color-white .navigation-box.filled svg circle {
    fill: #fff !important;
    stroke: #fff !important; }
  .color-white .sidebar-back {
    background-color: rgba(255, 255, 255, 0.05) !important; }
  .color-white.background-drop .square {
    background-color: rgba(255, 255, 255, 0.05) !important; }
  .color-white .dash:after {
    background: #fff !important; }
  .color-white #menu ul li.active a {
    background: #fff !important; }
  html.no-touch .color-white .bottom ul li:hover .ui.grid {
    background: #fff !important; }
    html.no-touch .color-white .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-white .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-white .bottom ul li.active .ui.grid {
    background: #fff !important; }
    .color-white .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-white .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-white.item.active .progress {
    background-color: rgba(255, 255, 255, 0.1) !important; }
    .color-white.item.active .progress .bar {
      background-color: #fff !important; }
  .color-white .ui.basic.button {
    color: #fff !important; }
    .color-white .ui.basic.button:hover {
      background-color: #fff !important;
      color: #fff !important; }
  .color-white textarea {
    border-bottom-color: #fff !important; }
  .color-white .textarea.done textarea {
    border-color: #fff !important; }
  .color-white .textarea.done .ui.basic.button {
    color: #fff !important; }
    .color-white .textarea.done .ui.basic.button:hover {
      background-color: #fff !important;
      color: #fff !important; }
  .color-white .textarea.done:after {
    background: #fff !important; }
  .color-white .clear {
    border-color: #fff !important; }
    .color-white .clear:hover {
      border-color: #fff !important; }
    .color-white .clear svg polygon {
      fill: #fff; }

.ui.popup.black {
  border-color: #2D3844 !important; }
  .ui.popup.black:before {
    box-shadow: 2px 2px 0px 0px #2D3844 !important; }

.color-black {
  border-color: #2D3844 !important; }
  .color-black *:not(textarea) {
    border-color: #2D3844 !important; }
  .color-black .boxed:after {
    background: #2D3844 !important; }
  .color-black .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #2D3844 !important; }
  html.no-touch .color-black .navigation-box:hover svg circle {
    fill: #2D3844 !important;
    stroke: #2D3844 !important; }
  .color-black .navigation-box.filled svg circle {
    fill: #2D3844 !important;
    stroke: #2D3844 !important; }
  .color-black .sidebar-back {
    background-color: rgba(45, 56, 68, 0.05) !important; }
  .color-black.background-drop .square {
    background-color: rgba(45, 56, 68, 0.05) !important; }
  .color-black .dash:after {
    background: #2D3844 !important; }
  .color-black #menu ul li.active a {
    background: #2D3844 !important; }
  html.no-touch .color-black .bottom ul li:hover .ui.grid {
    background: #2D3844 !important; }
    html.no-touch .color-black .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-black .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-black .bottom ul li.active .ui.grid {
    background: #2D3844 !important; }
    .color-black .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-black .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-black.item.active .progress {
    background-color: rgba(45, 56, 68, 0.1) !important; }
    .color-black.item.active .progress .bar {
      background-color: #2D3844 !important; }
  .color-black .ui.basic.button {
    color: #2D3844 !important; }
    .color-black .ui.basic.button:hover {
      background-color: #2D3844 !important;
      color: #fff !important; }
  .color-black textarea {
    border-bottom-color: #2D3844 !important; }
  .color-black .textarea.done textarea {
    border-color: #2D3844 !important; }
  .color-black .textarea.done .ui.basic.button {
    color: #2D3844 !important; }
    .color-black .textarea.done .ui.basic.button:hover {
      background-color: #2D3844 !important;
      color: #2D3844 !important; }
  .color-black .textarea.done:after {
    background: #2D3844 !important; }
  .color-black .clear {
    border-color: #fff !important; }
    .color-black .clear:hover {
      border-color: #2D3844 !important; }
    .color-black .clear svg polygon {
      fill: #2D3844; }

.ui.popup.green {
  border-color: #53BE4D !important; }
  .ui.popup.green:before {
    box-shadow: 2px 2px 0px 0px #53BE4D !important; }

.color-green {
  border-color: #53BE4D !important; }
  .color-green *:not(textarea) {
    border-color: #53BE4D !important; }
  .color-green .boxed:after {
    background: #53BE4D !important; }
  .color-green .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #53BE4D !important; }
  html.no-touch .color-green .navigation-box:hover svg circle {
    fill: #53BE4D !important;
    stroke: #53BE4D !important; }
  .color-green .navigation-box.filled svg circle {
    fill: #53BE4D !important;
    stroke: #53BE4D !important; }
  .color-green .sidebar-back {
    background-color: rgba(83, 190, 77, 0.05) !important; }
  .color-green.background-drop .square {
    background-color: rgba(83, 190, 77, 0.05) !important; }
  .color-green .dash:after {
    background: #53BE4D !important; }
  .color-green #menu ul li.active a {
    background: #53BE4D !important; }
  html.no-touch .color-green .bottom ul li:hover .ui.grid {
    background: #53BE4D !important; }
    html.no-touch .color-green .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-green .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-green .bottom ul li.active .ui.grid {
    background: #53BE4D !important; }
    .color-green .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-green .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-green.item.active .progress {
    background-color: rgba(83, 190, 77, 0.1) !important; }
    .color-green.item.active .progress .bar {
      background-color: #53BE4D !important; }
  .color-green .ui.basic.button {
    color: #53BE4D !important; }
    .color-green .ui.basic.button:hover {
      background-color: #53BE4D !important;
      color: #fff !important; }
  .color-green textarea {
    border-bottom-color: #53BE4D !important; }
  .color-green .textarea.done textarea {
    border-color: #53BE4D !important; }
  .color-green .textarea.done .ui.basic.button {
    color: #53BE4D !important; }
    .color-green .textarea.done .ui.basic.button:hover {
      background-color: #53BE4D !important;
      color: #53BE4D !important; }
  .color-green .textarea.done:after {
    background: #53BE4D !important; }
  .color-green .clear {
    border-color: #fff !important; }
    .color-green .clear:hover {
      border-color: #53BE4D !important; }
    .color-green .clear svg polygon {
      fill: #53BE4D; }

.ui.popup.lightgreen {
  border-color: #32AF84 !important; }
  .ui.popup.lightgreen:before {
    box-shadow: 2px 2px 0px 0px #32AF84 !important; }

.color-lightgreen {
  border-color: #32AF84 !important; }
  .color-lightgreen *:not(textarea) {
    border-color: #32AF84 !important; }
  .color-lightgreen .boxed:after {
    background: #32AF84 !important; }
  .color-lightgreen .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #32AF84 !important; }
  html.no-touch .color-lightgreen .navigation-box:hover svg circle {
    fill: #32AF84 !important;
    stroke: #32AF84 !important; }
  .color-lightgreen .navigation-box.filled svg circle {
    fill: #32AF84 !important;
    stroke: #32AF84 !important; }
  .color-lightgreen .sidebar-back {
    background-color: rgba(50, 175, 132, 0.05) !important; }
  .color-lightgreen.background-drop .square {
    background-color: rgba(50, 175, 132, 0.05) !important; }
  .color-lightgreen .dash:after {
    background: #32AF84 !important; }
  .color-lightgreen #menu ul li.active a {
    background: #32AF84 !important; }
  html.no-touch .color-lightgreen .bottom ul li:hover .ui.grid {
    background: #32AF84 !important; }
    html.no-touch .color-lightgreen .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-lightgreen .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-lightgreen .bottom ul li.active .ui.grid {
    background: #32AF84 !important; }
    .color-lightgreen .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-lightgreen .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-lightgreen.item.active .progress {
    background-color: rgba(50, 175, 132, 0.1) !important; }
    .color-lightgreen.item.active .progress .bar {
      background-color: #32AF84 !important; }
  .color-lightgreen .ui.basic.button {
    color: #32AF84 !important; }
    .color-lightgreen .ui.basic.button:hover {
      background-color: #32AF84 !important;
      color: #fff !important; }
  .color-lightgreen textarea {
    border-bottom-color: #32AF84 !important; }
  .color-lightgreen .textarea.done textarea {
    border-color: #32AF84 !important; }
  .color-lightgreen .textarea.done .ui.basic.button {
    color: #32AF84 !important; }
    .color-lightgreen .textarea.done .ui.basic.button:hover {
      background-color: #32AF84 !important;
      color: #32AF84 !important; }
  .color-lightgreen .textarea.done:after {
    background: #32AF84 !important; }
  .color-lightgreen .clear {
    border-color: #fff !important; }
    .color-lightgreen .clear:hover {
      border-color: #32AF84 !important; }
    .color-lightgreen .clear svg polygon {
      fill: #32AF84; }

.ui.popup.blue {
  border-color: #0097DC !important; }
  .ui.popup.blue:before {
    box-shadow: 2px 2px 0px 0px #0097DC !important; }

.color-blue {
  border-color: #0097DC !important; }
  .color-blue *:not(textarea) {
    border-color: #0097DC !important; }
  .color-blue .boxed:after {
    background: #0097DC !important; }
  .color-blue .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #0097DC !important; }
  html.no-touch .color-blue .navigation-box:hover svg circle {
    fill: #0097DC !important;
    stroke: #0097DC !important; }
  .color-blue .navigation-box.filled svg circle {
    fill: #0097DC !important;
    stroke: #0097DC !important; }
  .color-blue .sidebar-back {
    background-color: rgba(0, 151, 220, 0.05) !important; }
  .color-blue.background-drop .square {
    background-color: rgba(0, 151, 220, 0.05) !important; }
  .color-blue .dash:after {
    background: #0097DC !important; }
  .color-blue #menu ul li.active a {
    background: #0097DC !important; }
  html.no-touch .color-blue .bottom ul li:hover .ui.grid {
    background: #0097DC !important; }
    html.no-touch .color-blue .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-blue .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-blue .bottom ul li.active .ui.grid {
    background: #0097DC !important; }
    .color-blue .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-blue .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-blue.item.active .progress {
    background-color: rgba(0, 151, 220, 0.1) !important; }
    .color-blue.item.active .progress .bar {
      background-color: #0097DC !important; }
  .color-blue .ui.basic.button {
    color: #0097DC !important; }
    .color-blue .ui.basic.button:hover {
      background-color: #0097DC !important;
      color: #fff !important; }
  .color-blue textarea {
    border-bottom-color: #0097DC !important; }
  .color-blue .textarea.done textarea {
    border-color: #0097DC !important; }
  .color-blue .textarea.done .ui.basic.button {
    color: #0097DC !important; }
    .color-blue .textarea.done .ui.basic.button:hover {
      background-color: #0097DC !important;
      color: #0097DC !important; }
  .color-blue .textarea.done:after {
    background: #0097DC !important; }
  .color-blue .clear {
    border-color: #fff !important; }
    .color-blue .clear:hover {
      border-color: #0097DC !important; }
    .color-blue .clear svg polygon {
      fill: #0097DC; }

.ui.popup.lightblue {
  border-color: #F5FBFE !important; }
  .ui.popup.lightblue:before {
    box-shadow: 2px 2px 0px 0px #F5FBFE !important; }

.color-lightblue {
  border-color: #F5FBFE !important; }
  .color-lightblue *:not(textarea) {
    border-color: #F5FBFE !important; }
  .color-lightblue .boxed:after {
    background: #F5FBFE !important; }
  .color-lightblue .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #F5FBFE !important; }
  html.no-touch .color-lightblue .navigation-box:hover svg circle {
    fill: #F5FBFE !important;
    stroke: #F5FBFE !important; }
  .color-lightblue .navigation-box.filled svg circle {
    fill: #F5FBFE !important;
    stroke: #F5FBFE !important; }
  .color-lightblue .sidebar-back {
    background-color: rgba(245, 251, 254, 0.05) !important; }
  .color-lightblue.background-drop .square {
    background-color: rgba(245, 251, 254, 0.05) !important; }
  .color-lightblue .dash:after {
    background: #F5FBFE !important; }
  .color-lightblue #menu ul li.active a {
    background: #F5FBFE !important; }
  html.no-touch .color-lightblue .bottom ul li:hover .ui.grid {
    background: #F5FBFE !important; }
    html.no-touch .color-lightblue .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-lightblue .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-lightblue .bottom ul li.active .ui.grid {
    background: #F5FBFE !important; }
    .color-lightblue .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-lightblue .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-lightblue.item.active .progress {
    background-color: rgba(245, 251, 254, 0.1) !important; }
    .color-lightblue.item.active .progress .bar {
      background-color: #F5FBFE !important; }
  .color-lightblue .ui.basic.button {
    color: #F5FBFE !important; }
    .color-lightblue .ui.basic.button:hover {
      background-color: #F5FBFE !important;
      color: #fff !important; }
  .color-lightblue textarea {
    border-bottom-color: #F5FBFE !important; }
  .color-lightblue .textarea.done textarea {
    border-color: #F5FBFE !important; }
  .color-lightblue .textarea.done .ui.basic.button {
    color: #F5FBFE !important; }
    .color-lightblue .textarea.done .ui.basic.button:hover {
      background-color: #F5FBFE !important;
      color: #F5FBFE !important; }
  .color-lightblue .textarea.done:after {
    background: #F5FBFE !important; }
  .color-lightblue .clear {
    border-color: #fff !important; }
    .color-lightblue .clear:hover {
      border-color: #F5FBFE !important; }
    .color-lightblue .clear svg polygon {
      fill: #F5FBFE; }

.ui.popup.yellow {
  border-color: #F5C301 !important; }
  .ui.popup.yellow:before {
    box-shadow: 2px 2px 0px 0px #F5C301 !important; }

.color-yellow {
  border-color: #F5C301 !important; }
  .color-yellow *:not(textarea) {
    border-color: #F5C301 !important; }
  .color-yellow .boxed:after {
    background: #F5C301 !important; }
  .color-yellow .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #F5C301 !important; }
  html.no-touch .color-yellow .navigation-box:hover svg circle {
    fill: #F5C301 !important;
    stroke: #F5C301 !important; }
  .color-yellow .navigation-box.filled svg circle {
    fill: #F5C301 !important;
    stroke: #F5C301 !important; }
  .color-yellow .sidebar-back {
    background-color: rgba(245, 195, 1, 0.05) !important; }
  .color-yellow.background-drop .square {
    background-color: rgba(245, 195, 1, 0.05) !important; }
  .color-yellow .dash:after {
    background: #F5C301 !important; }
  .color-yellow #menu ul li.active a {
    background: #F5C301 !important; }
  html.no-touch .color-yellow .bottom ul li:hover .ui.grid {
    background: #F5C301 !important; }
    html.no-touch .color-yellow .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-yellow .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-yellow .bottom ul li.active .ui.grid {
    background: #F5C301 !important; }
    .color-yellow .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-yellow .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-yellow.item.active .progress {
    background-color: rgba(245, 195, 1, 0.1) !important; }
    .color-yellow.item.active .progress .bar {
      background-color: #F5C301 !important; }
  .color-yellow .ui.basic.button {
    color: #F5C301 !important; }
    .color-yellow .ui.basic.button:hover {
      background-color: #F5C301 !important;
      color: #fff !important; }
  .color-yellow textarea {
    border-bottom-color: #F5C301 !important; }
  .color-yellow .textarea.done textarea {
    border-color: #F5C301 !important; }
  .color-yellow .textarea.done .ui.basic.button {
    color: #F5C301 !important; }
    .color-yellow .textarea.done .ui.basic.button:hover {
      background-color: #F5C301 !important;
      color: #F5C301 !important; }
  .color-yellow .textarea.done:after {
    background: #F5C301 !important; }
  .color-yellow .clear {
    border-color: #fff !important; }
    .color-yellow .clear:hover {
      border-color: #F5C301 !important; }
    .color-yellow .clear svg polygon {
      fill: #F5C301; }

.ui.popup.orange {
  border-color: #E57E23 !important; }
  .ui.popup.orange:before {
    box-shadow: 2px 2px 0px 0px #E57E23 !important; }

.color-orange {
  border-color: #E57E23 !important; }
  .color-orange *:not(textarea) {
    border-color: #E57E23 !important; }
  .color-orange .boxed:after {
    background: #E57E23 !important; }
  .color-orange .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #E57E23 !important; }
  html.no-touch .color-orange .navigation-box:hover svg circle {
    fill: #E57E23 !important;
    stroke: #E57E23 !important; }
  .color-orange .navigation-box.filled svg circle {
    fill: #E57E23 !important;
    stroke: #E57E23 !important; }
  .color-orange .sidebar-back {
    background-color: rgba(229, 126, 35, 0.05) !important; }
  .color-orange.background-drop .square {
    background-color: rgba(229, 126, 35, 0.05) !important; }
  .color-orange .dash:after {
    background: #E57E23 !important; }
  .color-orange #menu ul li.active a {
    background: #E57E23 !important; }
  html.no-touch .color-orange .bottom ul li:hover .ui.grid {
    background: #E57E23 !important; }
    html.no-touch .color-orange .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-orange .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-orange .bottom ul li.active .ui.grid {
    background: #E57E23 !important; }
    .color-orange .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-orange .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-orange.item.active .progress {
    background-color: rgba(229, 126, 35, 0.1) !important; }
    .color-orange.item.active .progress .bar {
      background-color: #E57E23 !important; }
  .color-orange .ui.basic.button {
    color: #E57E23 !important; }
    .color-orange .ui.basic.button:hover {
      background-color: #E57E23 !important;
      color: #fff !important; }
  .color-orange textarea {
    border-bottom-color: #E57E23 !important; }
  .color-orange .textarea.done textarea {
    border-color: #E57E23 !important; }
  .color-orange .textarea.done .ui.basic.button {
    color: #E57E23 !important; }
    .color-orange .textarea.done .ui.basic.button:hover {
      background-color: #E57E23 !important;
      color: #E57E23 !important; }
  .color-orange .textarea.done:after {
    background: #E57E23 !important; }
  .color-orange .clear {
    border-color: #fff !important; }
    .color-orange .clear:hover {
      border-color: #E57E23 !important; }
    .color-orange .clear svg polygon {
      fill: #E57E23; }

.ui.popup.red {
  border-color: #C31A48 !important; }
  .ui.popup.red:before {
    box-shadow: 2px 2px 0px 0px #C31A48 !important; }

.color-red {
  border-color: #C31A48 !important; }
  .color-red *:not(textarea) {
    border-color: #C31A48 !important; }
  .color-red .boxed:after {
    background: #C31A48 !important; }
  .color-red .loader-animation svg path[fill="rgb(0,151,220)"] {
    fill: #C31A48 !important; }
  html.no-touch .color-red .navigation-box:hover svg circle {
    fill: #C31A48 !important;
    stroke: #C31A48 !important; }
  .color-red .navigation-box.filled svg circle {
    fill: #C31A48 !important;
    stroke: #C31A48 !important; }
  .color-red .sidebar-back {
    background-color: rgba(195, 26, 72, 0.05) !important; }
  .color-red.background-drop .square {
    background-color: rgba(195, 26, 72, 0.05) !important; }
  .color-red .dash:after {
    background: #C31A48 !important; }
  .color-red #menu ul li.active a {
    background: #C31A48 !important; }
  html.no-touch .color-red .bottom ul li:hover .ui.grid {
    background: #C31A48 !important; }
    html.no-touch .color-red .bottom ul li:hover .ui.grid .number {
      border-color: #fff !important; }
    html.no-touch .color-red .bottom ul li:hover .ui.grid .dash:after {
      background: #fff !important; }
  .color-red .bottom ul li.active .ui.grid {
    background: #C31A48 !important; }
    .color-red .bottom ul li.active .ui.grid .number {
      border-color: #fff !important; }
    .color-red .bottom ul li.active .ui.grid .dash:after {
      background: #fff !important; }
  .color-red.item.active .progress {
    background-color: rgba(195, 26, 72, 0.1) !important; }
    .color-red.item.active .progress .bar {
      background-color: #C31A48 !important; }
  .color-red .ui.basic.button {
    color: #C31A48 !important; }
    .color-red .ui.basic.button:hover {
      background-color: #C31A48 !important;
      color: #fff !important; }
  .color-red textarea {
    border-bottom-color: #C31A48 !important; }
  .color-red .textarea.done textarea {
    border-color: #C31A48 !important; }
  .color-red .textarea.done .ui.basic.button {
    color: #C31A48 !important; }
    .color-red .textarea.done .ui.basic.button:hover {
      background-color: #C31A48 !important;
      color: #C31A48 !important; }
  .color-red .textarea.done:after {
    background: #C31A48 !important; }
  .color-red .clear {
    border-color: #fff !important; }
    .color-red .clear:hover {
      border-color: #C31A48 !important; }
    .color-red .clear svg polygon {
      fill: #C31A48; }

body.intro section#main {
  -ms-flex-align: normal;
      align-items: normal;
  padding: 125px 100px;
  display: block; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body.intro section#main {
      padding-top: 50px; } }

body.intro .content-wrapper {
  width: 60%; }

body.intro .ui.page.modals {
  background-color: rgba(0, 0, 0, 0.4); }

body.intro .ui.modal {
  box-shadow: 10px 10px 0px #2D3844;
  width: 550px;
  height: 350px;
  border: 2px solid #2D3844;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -275px -175px;
  border-radius: 0px; }
  body.intro .ui.modal .content {
    background: #f5fbfe;
    padding: 0px;
    height: 100%; }
    body.intro .ui.modal .content header {
      background: #fff;
      text-align: center;
      padding: 40px 0px; }
      body.intro .ui.modal .content header h2 {
        font-size: 40px; }
      body.intro .ui.modal .content header .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px; }
    body.intro .ui.modal .content section {
      padding: 40px 30px 0px;
      text-align: center; }

body.intro .content {
  padding-right: 35px; }
  body.intro .content h1 {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 600; }
    @media screen and (max-width: 992px) {
      body.intro .content h1 {
        font-size: 30px; } }
  body.intro .content p {
    margin-bottom: 20px; }
  body.intro .content ul {
    list-style-type: none;
    padding: 0px; }
    body.intro .content ul li {
      margin-bottom: 8px; }
      body.intro .content ul li span:first-of-type {
        border: 2px solid #2D3844;
        border-radius: 50%;
        height: 28px;
        width: 28px;
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        padding: 3px 0;
        text-align: center;
        margin-right: 15px; }
      body.intro .content ul li span.dash {
        display: inline-block;
        height: 3px;
        background: #2D3844;
        width: 15px;
        margin-right: 15px;
        -webkit-transform: translateY(-2px);
                transform: translateY(-2px); }

body.intro .action {
  margin-top: 30px; }
  body.intro .action h3 {
    font-size: 24px;
    font-family: "Lato", sans-serif !important; }
  body.intro .action svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    body.intro .action svg g {
      opacity: 1; }
    body.intro .action svg circle {
      fill: #F5C301;
      stroke: #F5C301; }
    body.intro .action svg polyline {
      stroke: #fff; }
  body.intro .action .column:first-of-type {
    padding-right: 50px;
    padding-top: 3px; }

body.intro .background-svg svg {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 1920px;
  -webkit-transform: translate(30%, 35%);
          transform: translate(30%, 35%);
  z-index: -1; }

body.intro .background-svg img {
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 60%;
  z-index: -1; }

body.intro .dots svg {
  position: absolute;
  top: 0px;
  -webkit-transform: translate(-50px, 170px);
          transform: translate(-50px, 170px);
  z-index: -1; }

body.questions section#main {
  -ms-flex-align: normal;
      align-items: normal;
  padding: 75px;
  display: block; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body.questions section#main {
      padding: 75px 30px; } }

body.questions .questions.ui.container {
  position: relative; }
  body.questions .questions.ui.container .dots {
    position: absolute;
    top: -90px;
    left: -200px;
    z-index: -1; }

body.questions .questions-wrapper {
  border: 2px solid #F5C301;
  box-shadow: 15px 15px 0px #F5C301;
  padding: 60px 100px 60px 100px !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important;
  background: #fff; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body.questions .questions-wrapper {
      padding: 60px 50px 60px 50px !important; } }
  body.questions .questions-wrapper h1 {
    font-size: 40px;
    margin-bottom: 70px; }
  body.questions .questions-wrapper .group h4 {
    font-size: 24px;
    font-family: "Lato", sans-serif !important;
    margin-bottom: 30px; }
  body.questions .questions-wrapper .group .ui.basic.button {
    margin-bottom: 50px;
    margin-right: 30px;
    min-width: 150px; }
  body.questions .questions-wrapper .group .dropdown-wrapper {
    position: relative; }
    body.questions .questions-wrapper .group .dropdown-wrapper .ui.upward.selection.dropdown:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0); }
    body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown {
      border-width: 0px 0px 2px 0px;
      border-radius: 0px;
      border-color: #F5C301; }
      body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .icon.dropdown {
        display: none; }
      body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown.active {
        box-shadow: 0px 0px 0px #000; }
      body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .text {
        font-size: 20px; }
      body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu {
        border: 2px solid #F5C301;
        box-shadow: 0px 0px 0px #000;
        border-radius: 0px;
        border-top-width: 2px !important; }
        body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu .item {
          background: #fff;
          border: 0px solid #000; }
          body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu .item span {
            font-family: "Lato", sans-serif !important;
            font-size: 16px; }
          body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu .item:hover {
            background: #F5C301;
            color: #fff; }
            body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu .item:hover span {
              color: #fff; }
          body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu .item.selected {
            background: #F5C301;
            color: #fff; }
            body.questions .questions-wrapper .group .dropdown-wrapper .ui.dropdown .menu .item.selected span {
              color: #fff; }
    body.questions .questions-wrapper .group .dropdown-wrapper svg {
      position: absolute;
      top: 15px;
      right: 30px;
      z-index: 100;
      pointer-events: none; }
  body.questions .questions-wrapper .link-wrapper {
    opacity: 0.3;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    pointer-events: none; }
    body.questions .questions-wrapper .link-wrapper.active {
      pointer-events: initial;
      opacity: 1; }
      body.questions .questions-wrapper .link-wrapper.active:hover {
        cursor: pointer; }
    body.questions .questions-wrapper .link-wrapper .action {
      margin-top: 50px; }
      body.questions .questions-wrapper .link-wrapper .action svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
        body.questions .questions-wrapper .link-wrapper .action svg g {
          opacity: 1; }
        body.questions .questions-wrapper .link-wrapper .action svg circle {
          fill: #F5C301;
          stroke: #F5C301; }
        body.questions .questions-wrapper .link-wrapper .action svg polyline {
          stroke: #fff; }

@media screen and (max-height: 850px) {
  body.survey section#main .fp-tableCell {
    padding-top: 0px; } }

@media screen and (max-height: 600px) {
  body.survey section#main .fp-tableCell {
    padding-top: 50px; } }

@media screen and (max-height: 400px) {
  body.survey section#main .fp-tableCell {
    padding-top: 100px; } }

body.survey .background-drop {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  body.survey .background-drop .ui.container {
    width: 1090px !important;
    height: 390px;
    position: relative; }
    body.survey .background-drop .ui.container .dots {
      position: absolute;
      top: -230px;
      left: -150px;
      z-index: 120; }
    body.survey .background-drop .ui.container .square {
      position: absolute;
      width: 300px;
      height: 300px;
      background: #F5FBFE;
      z-index: 100;
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      -webkit-transform: translate(-60px, -140px);
              transform: translate(-60px, -140px);
      will-change: transform; }
  body.survey .background-drop.start-overlay .ui.container .dots {
    position: absolute;
    top: -230px;
    left: 360px;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-transition: 550ms all ease;
    transition: 550ms all ease; }
  body.survey .background-drop.start-overlay .ui.container .square {
    -webkit-transition: 550ms all ease;
    transition: 550ms all ease;
    -webkit-transform: scale(6) translate(29px, 0%);
            transform: scale(6) translate(29px, 0%); }
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      body.survey .background-drop.start-overlay .ui.container .square {
        -webkit-transform: scale(6) translate(23px, 0%);
                transform: scale(6) translate(23px, 0%); } }

body.survey .end-overlay .sidebar-back {
  width: 100%;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease; }

body.survey .sidebar-back {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 300px;
  height: 100%;
  background: #F5FBFE;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  z-index: 200;
  transition: 500ms all ease; }
  @media screen and (max-width: 1800px) {
    body.survey .sidebar-back {
      width: 200px; } }
  body.survey .sidebar-back.hide {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  body.survey .sidebar-back:after {
    content: '';
    position: absolute;
    background: #fff;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    -webkit-transform: translateZ(-1px);
            transform: translateZ(-1px); }

body.survey .sidebar {
  position: fixed;
  top: 0px;
  right: 10px;
  width: 300px;
  height: 100%;
  z-index: 50;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease; }
  @media screen and (max-width: 1800px) {
    body.survey .sidebar {
      width: 200px; } }
  body.survey .sidebar.hide {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  body.survey .sidebar #menu ul {
    position: absolute;
    top: 50%;
    left: calc(50% + 10px);
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    list-style: none;
    padding: 0px;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease;
    z-index: -1; }
    body.survey .sidebar #menu ul.show {
      opacity: 1;
      z-index: 100; }
    body.survey .sidebar #menu ul li a {
      line-height: 0;
      font-size: 0;
      color: transparent;
      width: 14px;
      height: 14px;
      background: #fff;
      display: block;
      border-radius: 50%;
      border: 1px solid #53BE4D;
      margin: 18px 0px;
      -webkit-transition: 700ms all ease;
      transition: 700ms all ease; }
    body.survey .sidebar #menu ul li.active a {
      height: 40px;
      background: #53BE4D;
      border-radius: 14px; }

body.survey #fullpage {
  -webkit-transform: translatez(0);
          transform: translatez(0); }
  body.survey #fullpage .section {
    opacity: 0.03;
    -webkit-transition: 500ms ease all;
    transition: 500ms ease all;
    border-top: 0px solid #000;
    border-bottom: 0px solid #000; }
    body.survey #fullpage .section .scroll-wrapper {
      height: calc(100% - 70px);
      overflow-y: scroll;
      overflow-x: hidden; }
    body.survey #fullpage .section.fp-completely .ui.container.bottom:after {
      display: none; }
    body.survey #fullpage .section.active {
      opacity: 1; }
    body.survey #fullpage .section .ui.container {
      width: 1090px !important; }
      @media screen and (max-width: 1550px) {
        body.survey #fullpage .section .ui.container.top, body.survey #fullpage .section .ui.container.bottom {
          width: 800px !important;
          -webkit-transform: translateX(-100px);
                  transform: translateX(-100px); } }
      @media screen and (max-width: 1250px) {
        body.survey #fullpage .section .ui.container {
          width: 100% !important; }
          body.survey #fullpage .section .ui.container.top, body.survey #fullpage .section .ui.container.bottom {
            width: 700px !important;
            -webkit-transform: translateX(-100px);
                    transform: translateX(-100px); } }
    body.survey #fullpage .section.start h1 {
      font-size: 40px; }
    body.survey #fullpage .section.start .column:last-of-type {
      padding-left: 50px;
      padding-right: 50px; }
    body.survey #fullpage .section.start .huset {
      padding-left: 50px; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        body.survey #fullpage .section.start .huset img {
          width: 90%; } }
    body.survey #fullpage .section.start section.bottom-navigation {
      min-height: auto;
      height: auto;
      padding: 100px 0px 0px; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        body.survey #fullpage .section.start section.bottom-navigation .two.column {
          padding: 0px 60px !important; } }
    body.survey #fullpage .section.question .top > .ui.grid {
      border-bottom: 2px solid #0097DC; }
    body.survey #fullpage .section.question .top .number {
      font-size: 100px;
      font-weight: bold;
      line-height: 100px; }
      @media screen and (max-height: 600px) {
        body.survey #fullpage .section.question .top .number {
          font-size: 50px;
          line-height: 50px; } }
    body.survey #fullpage .section.question .top h2 {
      font-size: 30px;
      font-weight: bold; }
      @media screen and (max-height: 600px) {
        body.survey #fullpage .section.question .top h2 {
          font-size: 20px; } }
    body.survey #fullpage .section.question .bottom:after {
      content: '';
      background: transparent;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px; }
    body.survey #fullpage .section.question .bottom .sixteen {
      padding-left: 0px !important;
      padding-right: 0px !important; }
    body.survey #fullpage .section.question .bottom ul {
      list-style: none;
      padding: 0px; }
      body.survey #fullpage .section.question .bottom ul li {
        will-change: opacity; }
        html.no-touch body.survey #fullpage .section.question .bottom ul li:hover .ui.grid {
          background: #0097DC; }
          html.no-touch body.survey #fullpage .section.question .bottom ul li:hover .ui.grid .dash:after {
            background: #fff; }
          html.no-touch body.survey #fullpage .section.question .bottom ul li:hover .ui.grid .number {
            border: 3px solid #fff; }
          html.no-touch body.survey #fullpage .section.question .bottom ul li:hover .ui.grid .text,
          html.no-touch body.survey #fullpage .section.question .bottom ul li:hover .ui.grid h4 {
            color: #fff; }
        body.survey #fullpage .section.question .bottom ul li.active .ui.grid {
          background: #0097DC; }
          body.survey #fullpage .section.question .bottom ul li.active .ui.grid .dash:after {
            background: #fff; }
          body.survey #fullpage .section.question .bottom ul li.active .ui.grid .number {
            border: 3px solid #fff; }
          body.survey #fullpage .section.question .bottom ul li.active .ui.grid .text,
          body.survey #fullpage .section.question .bottom ul li.active .ui.grid h4 {
            color: #fff; }
        body.survey #fullpage .section.question .bottom ul li .ui.grid {
          margin: 0px 0px;
          padding: 10px 0px;
          -webkit-transition: 200ms all ease;
          transition: 200ms all ease; }
          @media screen and (max-height: 600px) {
            body.survey #fullpage .section.question .bottom ul li .ui.grid {
              padding: 5px 0px; } }
          @media screen and (max-height: 600px) {
            body.survey #fullpage .section.question .bottom ul li .ui.grid .text {
              font-size: 14px; } }
          @media screen and (max-width: 1250px) {
            body.survey #fullpage .section.question .bottom ul li .ui.grid .text {
              width: 70% !important; } }
        body.survey #fullpage .section.question .bottom ul li.active {
          -webkit-animation: 0.25s ease 0s 2 normal none running flash;
                  animation: 0.25s ease 0s 2 normal none running flash; }

@-webkit-keyframes flash {
  50% {
    opacity: 0.5; } }

@keyframes flash {
  50% {
    opacity: 0.5; } }
          body.survey #fullpage .section.question .bottom ul li.active .ui.grid {
            background: #0097DC; }
        body.survey #fullpage .section.question .bottom ul li .tast {
          color: #fff;
          padding-right: 0px !important;
          width: 50px !important; }
        body.survey #fullpage .section.question .bottom ul li .number {
          width: 37px !important;
          height: 37px;
          border-radius: 50%;
          border: 2px solid #0097DC;
          position: relative; }
          body.survey #fullpage .section.question .bottom ul li .number h4 {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
            font-size: 18px; }
        body.survey #fullpage .section.question .bottom ul li .dash {
          position: relative;
          margin: 0px 20px 0px 0px;
          width: 30px !important;
          padding: 0px !important; }
          body.survey #fullpage .section.question .bottom ul li .dash:after {
            content: "";
            position: absolute;
            top: 45%;
            left: 50%;
            width: 17px;
            height: 3px;
            background: #0097DC; }
        body.survey #fullpage .section.question .bottom ul li .text {
          padding: 0px 0px !important;
          font-size: 18px; }
    body.survey #fullpage .section.summary {
      padding-top: 75px;
      margin-bottom: 75px;
      display: block;
      table-layout: auto; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        body.survey #fullpage .section.summary {
          padding-top: 70px; } }
      body.survey #fullpage .section.summary section.top-navigation {
        padding: 50px 0px; }
      body.survey #fullpage .section.summary .fp-tableCell {
        display: block; }
      body.survey #fullpage .section.summary .summary-list {
        padding: 40px 0px 0px; }
        @media screen and (max-width: 1050px) {
          body.survey #fullpage .section.summary .summary-list {
            width: 70% !important;
            -webkit-transform: translateX(-90px);
                    transform: translateX(-90px); } }
        @media screen and (max-width: 1500px) {
          body.survey #fullpage .section.summary .summary-list {
            width: 70% !important;
            -webkit-transform: translateX(-90px);
                    transform: translateX(-90px); } }
      body.survey #fullpage .section.summary table thead tr {
        margin-bottom: 20px; }
        body.survey #fullpage .section.summary table thead tr th {
          font-size: 30px;
          font-weight: bold;
          border-bottom-width: 2px;
          padding: 30px 0px; }
      body.survey #fullpage .section.summary table tbody tr:first-child td {
        padding-top: 50px; }
      body.survey #fullpage .section.summary table tbody tr td {
        border-top-width: 0px; }
        body.survey #fullpage .section.summary table tbody tr td:first-child {
          padding-left: 25px; }
        body.survey #fullpage .section.summary table tbody tr td.number {
          font-size: 30px;
          font-weight: bold; }
        body.survey #fullpage .section.summary table tbody tr td.question {
          padding-left: 0px;
          font-size: 16px; }
        body.survey #fullpage .section.summary table tbody tr td.answer span {
          font-size: 18px;
          font-weight: bold;
          border: 2px solid;
          border-radius: 50%;
          width: 37px;
          height: 37px;
          padding: 7px 13px; }
      body.survey #fullpage .section.summary .bottom-navigation {
        background: transparent;
        margin-top: 100px; }
    body.survey #fullpage .section.comments {
      padding-top: 75px;
      margin-bottom: 75px;
      display: block;
      table-layout: auto; }
      body.survey #fullpage .section.comments section.top-navigation {
        padding: 50px 0px; }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        body.survey #fullpage .section.comments {
          padding-top: 70px; } }
      body.survey #fullpage .section.comments .fp-tableCell {
        display: block; }
      body.survey #fullpage .section.comments .comments {
        padding-bottom: 115px;
        padding-top: 30px; }
        @media screen and (max-width: 1050px) {
          body.survey #fullpage .section.comments .comments {
            width: 70% !important;
            -webkit-transform: translateX(-90px);
                    transform: translateX(-90px); } }
        @media screen and (max-width: 1500px) {
          body.survey #fullpage .section.comments .comments {
            width: 70% !important;
            -webkit-transform: translateX(-90px);
                    transform: translateX(-90px); } }
        body.survey #fullpage .section.comments .comments .comment {
          margin-bottom: 80px; }
          body.survey #fullpage .section.comments .comments .comment:last-child {
            margin-bottom: 0px; }
          body.survey #fullpage .section.comments .comments .comment .header {
            padding: 0px 20px; }
            body.survey #fullpage .section.comments .comments .comment .header h3 {
              font-size: 24px;
              font-weight: bold; }
            body.survey #fullpage .section.comments .comments .comment .header p {
              font-size: 20px; }
          body.survey #fullpage .section.comments .comments .comment .textarea {
            position: relative;
            z-index: 100;
            margin-top: 40px; }
            body.survey #fullpage .section.comments .comments .comment .textarea textarea {
              border: 2px solid transparent;
              border-top-color: transparent;
              border-left-color: transparent;
              border-right-color: transparent;
              border-bottom: 3px solid #0097DC;
              background: transparent;
              width: 100%;
              font-size: 18px;
              padding: 20px 250px 20px 20px;
              resize: none;
              line-height: 1.5;
              overflow: hidden; }
              body.survey #fullpage .section.comments .comments .comment .textarea textarea:focus, body.survey #fullpage .section.comments .comments .comment .textarea textarea:hover, body.survey #fullpage .section.comments .comments .comment .textarea textarea:active {
                outline: 0; }
              body.survey #fullpage .section.comments .comments .comment .textarea textarea::-webkit-input-placeholder {
                color: rgba(45, 56, 68, 0.3); }
              body.survey #fullpage .section.comments .comments .comment .textarea textarea::-moz-placeholder {
                color: rgba(45, 56, 68, 0.3); }
              body.survey #fullpage .section.comments .comments .comment .textarea textarea:-ms-input-placeholder {
                color: rgba(45, 56, 68, 0.3); }
              body.survey #fullpage .section.comments .comments .comment .textarea textarea:-moz-placeholder {
                color: rgba(45, 56, 68, 0.3); }
            body.survey #fullpage .section.comments .comments .comment .textarea .ui.basic.button {
              border: 2px solid #0097DC;
              color: #0097DC;
              border-radius: 33px;
              padding: 18px 20px;
              min-width: 190px;
              min-height: 66px;
              font-size: 20px;
              font-weight: bold;
              box-shadow: 0px 0px 0px #000;
              position: absolute;
              bottom: 25px;
              right: 30px;
              -webkit-transition: 100ms all ease;
              transition: 100ms all ease; }
              body.survey #fullpage .section.comments .comments .comment .textarea .ui.basic.button:active, body.survey #fullpage .section.comments .comments .comment .textarea .ui.basic.button:focus {
                background: transparent !important; }
              body.survey #fullpage .section.comments .comments .comment .textarea .ui.basic.button:hover {
                background-color: #0097DC;
                color: #fff; }
            body.survey #fullpage .section.comments .comments .comment .textarea .clear {
              display: none;
              position: absolute;
              top: 19px;
              right: 24px;
              border: 2px solid transparent;
              padding: 8px 8px 5px; }
              body.survey #fullpage .section.comments .comments .comment .textarea .clear:hover {
                border-radius: 50%;
                border: 2px solid #0097DC;
                cursor: pointer; }
            body.survey #fullpage .section.comments .comments .comment .textarea.done:after {
              content: '';
              position: absolute;
              top: 0px;
              left: 0px;
              right: 0px;
              bottom: 0px;
              background: #0097DC;
              z-index: -1;
              -webkit-transform: translate3D(15px, 15px, -1px);
                      transform: translate3D(15px, 15px, -1px); }
            body.survey #fullpage .section.comments .comments .comment .textarea.done textarea {
              background: #fff;
              padding: 80px 120px 80px 150px;
              border-width: 2px 2px 2px 2px !important;
              border-color: #0097DC; }
            body.survey #fullpage .section.comments .comments .comment .textarea.done .ui.basic.button {
              border: 0px solid #0097DC !important;
              font-size: 14px;
              bottom: auto;
              top: 28px;
              right: 72px;
              min-width: 50px;
              min-height: 14px;
              padding: 0px;
              margin: 0px;
              -webkit-transition: 0ms all ease;
              transition: 0ms all ease; }
              body.survey #fullpage .section.comments .comments .comment .textarea.done .ui.basic.button:hover {
                background-color: transparent !important;
                color: #0097DC;
                text-decoration: underline; }
            body.survey #fullpage .section.comments .comments .comment .textarea.done .clear {
              display: block; }
    body.survey #fullpage .section section.bottom-navigation {
      padding: 50px 0px;
      background: #fff;
      min-height: 220px; }

body.complete header#navigation {
  position: absolute; }

body.complete section#main {
  -ms-flex-align: normal;
      align-items: normal;
  padding: 125px;
  display: block; }
  body.complete section#main .navigation-box.box-left {
    text-align: left; }
  body.complete section#main .navigation-box svg g,
  body.complete section#main .navigation-box svg circle,
  body.complete section#main .navigation-box svg polyline {
    opacity: 1;
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease; }
  body.complete section#main .navigation-box:hover svg circle {
    fill: #2D3844;
    stroke: #2D3844;
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease; }
  body.complete section#main .navigation-box:hover svg polyline {
    stroke: #fff;
    -webkit-transition: 200ms all ease;
    transition: 200ms all ease; }
  body.complete section#main button,
  body.complete section#main a {
    min-width: 200px;
    padding: 21px 30px;
    border-radius: 33px; }
  body.complete section#main .top-section {
    margin-top: 60px; }
    body.complete section#main .top-section .content {
      padding-left: 85px; }
      @media screen and (max-width: 1050px) {
        body.complete section#main .top-section .content {
          padding-left: 10px; } }
      body.complete section#main .top-section .content h1 {
        font-size: 40px;
        margin-top: 20px;
        padding-bottom: 0px; }
      body.complete section#main .top-section .content h3 {
        font-size: 30px; }
    body.complete section#main .top-section .dots svg {
      position: absolute;
      bottom: 10px;
      left: -120px;
      z-index: -1; }
    body.complete section#main .top-section .download-top svg {
      margin-bottom: 20px; }
    body.complete section#main .top-section .download-top button,
    body.complete section#main .top-section .download-top a {
      left: 50%;
      bottom: -20px;
      position: absolute;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  body.complete section#main .results {
    margin-top: 150px; }
    body.complete section#main .results .result {
      border-bottom: 2px solid #0097DC;
      padding-bottom: 0px;
      margin-bottom: 100px; }
      body.complete section#main .results .result .title {
        padding-left: 95px; }
        @media screen and (max-width: 1050px) {
          body.complete section#main .results .result .title {
            padding-left: 25px; } }
        body.complete section#main .results .result .title h2 {
          font-size: 40px; }
      body.complete section#main .results .result .text {
        font-size: 18px;
        padding-top: 30px; }
      body.complete section#main .results .result .score {
        padding-left: 30px; }
        body.complete section#main .results .result .score span {
          font-size: 30px;
          border-radius: 50%;
          border: 2px solid #2D3844 !important;
          padding: 26px 0px;
          text-align: center;
          font-weight: bold;
          width: 75px;
          height: 75px;
          display: block; }
  body.complete section#main .final-notes {
    margin-bottom: 100px;
    position: relative; }
    body.complete section#main .final-notes .content {
      padding-left: 95px;
      padding-right: 200px; }
      @media screen and (max-width: 1050px) {
        body.complete section#main .final-notes .content {
          padding-left: 10px;
          padding-right: 100px; } }
    body.complete section#main .final-notes .download-bottom button,
    body.complete section#main .final-notes .download-bottom a {
      left: 50%;
      bottom: 0px;
      position: absolute;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
    body.complete section#main .final-notes .dots svg {
      position: absolute;
      left: -250px;
      bottom: -80px;
      z-index: -1; }
  body.complete section#main .thank-you h2 {
    font-size: 40px; }
  body.complete section#main .mountain-complete {
    position: relative; }
    body.complete section#main .mountain-complete img {
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  body.complete section#main .final-links {
    width: 1400px;
    margin-top: 550px; }
    body.complete section#main .final-links .column {
      padding: 0px 20px; }
      @media screen and (max-width: 1050px) {
        body.complete section#main .final-links .column {
          width: 100% !important; } }
      body.complete section#main .final-links .column .inner {
        background: #fff;
        box-shadow: 15px 15px 0px #2D3844;
        border: 2px solid #2D3844;
        padding: 40px 50px 40px;
        width: 100%;
        text-align: center; }
      body.complete section#main .final-links .column h3 {
        font-size: 24px;
        margin-bottom: 4px; }
      body.complete section#main .final-links .column p {
        font-size: 18px; }
        body.complete section#main .final-links .column p strong {
          display: inline-block; }
      body.complete section#main .final-links .column .arrow {
        position: absolute;
        bottom: 0px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        padding-right: 70px; }
        body.complete section#main .final-links .column .arrow svg {
          position: absolute;
          top: 0px;
          right: -20px;
          -webkit-transition: 200ms all ease;
          transition: 200ms all ease;
          -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
          body.complete section#main .final-links .column .arrow svg g,
          body.complete section#main .final-links .column .arrow svg circle,
          body.complete section#main .final-links .column .arrow svg polyline {
            opacity: 1;
            -webkit-transition: 200ms all ease;
            transition: 200ms all ease; }
        body.complete section#main .final-links .column .arrow:hover svg circle {
          fill: #2D3844;
          stroke: #2D3844;
          -webkit-transition: 200ms all ease;
          transition: 200ms all ease; }
        body.complete section#main .final-links .column .arrow:hover svg polyline {
          stroke: #fff;
          -webkit-transition: 200ms all ease;
          transition: 200ms all ease; }
  body.complete section#main .pages {
    position: absolute;
    left: -999em;
    top: -999em; }

body.pdf section#main,
body.complete section#main {
  -ms-flex-align: normal;
      align-items: normal;
  padding: 125px;
  display: block; }
  body.pdf section#main .pages .page,
  body.complete section#main .pages .page {
    width: 29.7cm;
    min-height: 21cm;
    padding: 0px;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid !important;
    border-radius: 0px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    position: relative; }
    body.pdf section#main .pages .page .logo,
    body.complete section#main .pages .page .logo {
      position: absolute;
      bottom: 10px;
      right: 10px; }
    body.pdf section#main .pages .page header,
    body.complete section#main .pages .page header {
      border-bottom: 2px solid #53BE4D; }
      body.pdf section#main .pages .page header .column.title,
      body.complete section#main .pages .page header .column.title {
        height: 135px;
        padding: 50px 60px; }
      body.pdf section#main .pages .page header .column.number,
      body.complete section#main .pages .page header .column.number {
        padding: 0px 30px 0px 0px; }
        body.pdf section#main .pages .page header .column.number span,
        body.complete section#main .pages .page header .column.number span {
          border-radius: 50%;
          border: 2px solid #2D3844 !important;
          padding: 20px 18px;
          font-size: 22px;
          font-weight: bold; }
    body.pdf section#main .pages .page.first section,
    body.complete section#main .pages .page.first section {
      padding: 50px 20px; }
      body.pdf section#main .pages .page.first section .ui.container.score,
      body.complete section#main .pages .page.first section .ui.container.score {
        padding: 80px 50px 50px; }
        body.pdf section#main .pages .page.first section .ui.container.score .column,
        body.complete section#main .pages .page.first section .ui.container.score .column {
          padding: 0px 7px; }
          body.pdf section#main .pages .page.first section .ui.container.score .column .range,
          body.complete section#main .pages .page.first section .ui.container.score .column .range {
            height: 9px;
            border-radius: 5px;
            padding: 0px; }
            body.pdf section#main .pages .page.first section .ui.container.score .column .range.bad,
            body.complete section#main .pages .page.first section .ui.container.score .column .range.bad {
              background: #C31A48; }
            body.pdf section#main .pages .page.first section .ui.container.score .column .range.okay,
            body.complete section#main .pages .page.first section .ui.container.score .column .range.okay {
              background: #F5C301; }
            body.pdf section#main .pages .page.first section .ui.container.score .column .range.great,
            body.complete section#main .pages .page.first section .ui.container.score .column .range.great {
              background: #53BE4D; }
        body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper,
        body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper {
          position: relative;
          margin: 0px 3px; }
          body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .marker,
          body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .marker {
            position: absolute;
            top: -63px;
            left: 70%;
            width: 40px;
            height: 40px;
            background: #2D3844;
            text-align: center;
            padding: 10px 0px;
            border-radius: 50%;
            font-weight: bold;
            font-size: 16px;
            color: #fff;
            -webkit-transform: translateX(-20px);
                    transform: translateX(-20px);
            z-index: 30; }
            body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .marker .arrow-down,
            body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .marker .arrow-down {
              position: absolute;
              bottom: -6px;
              left: 50%;
              -webkit-transform: translateX(-50%);
                      transform: translateX(-50%);
              width: 0;
              height: 0;
              border-left: 8px solid transparent !important;
              border-right: 8px solid transparent !important;
              border-top: 10px solid #f00 !important; }
            body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .marker.green .arrow-down,
            body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .marker.green .arrow-down {
              border-top: 10px solid #53BE4D !important; }
            body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .marker.yellow .arrow-down,
            body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .marker.yellow .arrow-down {
              border-top: 10px solid #F5C301 !important; }
            body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .marker.red .arrow-down,
            body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .marker.red .arrow-down {
              border-top: 10px solid #C31A48 !important; }
          body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .number,
          body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .number {
            position: absolute;
            top: -53px;
            left: 70%;
            font-size: 18px;
            font-weight: bold;
            -webkit-transform: translateX(-5px);
                    transform: translateX(-5px);
            z-index: 10; }
          body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .number-1,
          body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .number-1 {
            left: 0%; }
          body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .number-3,
          body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .number-3 {
            left: 33.3%;
            display: none; }
          body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .number-4,
          body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .number-4 {
            left: 66.6%;
            display: none; }
          body.pdf section#main .pages .page.first section .ui.container.score .marker-wrapper .number-5,
          body.complete section#main .pages .page.first section .ui.container.score .marker-wrapper .number-5 {
            left: 100%; }
      body.pdf section#main .pages .page.first section .ui.container.summary .column:first-of-type,
      body.complete section#main .pages .page.first section .ui.container.summary .column:first-of-type {
        border-right: 1px solid rgba(45, 56, 68, 0.1) !important;
        padding-right: 30px; }
      body.pdf section#main .pages .page.first section .ui.container.summary .column:last-of-type,
      body.complete section#main .pages .page.first section .ui.container.summary .column:last-of-type {
        padding-right: 30px; }
      body.pdf section#main .pages .page.first section .ui.container.summary table thead tr,
      body.complete section#main .pages .page.first section .ui.container.summary table thead tr {
        margin-bottom: 20px; }
        body.pdf section#main .pages .page.first section .ui.container.summary table thead tr th,
        body.complete section#main .pages .page.first section .ui.container.summary table thead tr th {
          font-size: 30px;
          font-weight: bold;
          border-bottom-width: 2px;
          padding: 30px 0px; }
      body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr,
      body.complete section#main .pages .page.first section .ui.container.summary table tbody tr {
        border-bottom: 0px solid #2D3844 !important; }
        body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr:first-child td,
        body.complete section#main .pages .page.first section .ui.container.summary table tbody tr:first-child td {
          padding-top: 0px; }
        body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr td,
        body.complete section#main .pages .page.first section .ui.container.summary table tbody tr td {
          border-top-width: 0px;
          padding-bottom: 30px; }
          body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr td:first-child,
          body.complete section#main .pages .page.first section .ui.container.summary table tbody tr td:first-child {
            padding-left: 25px; }
          body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr td.number,
          body.complete section#main .pages .page.first section .ui.container.summary table tbody tr td.number {
            font-size: 16px;
            font-weight: bold; }
          body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr td.question,
          body.complete section#main .pages .page.first section .ui.container.summary table tbody tr td.question {
            padding-left: 0px; }
          body.pdf section#main .pages .page.first section .ui.container.summary table tbody tr td.answer span,
          body.complete section#main .pages .page.first section .ui.container.summary table tbody tr td.answer span {
            font-size: 18px;
            font-weight: bold;
            border: 2px solid #2D3844 !important;
            border-radius: 50%;
            width: 37px;
            height: 37px;
            padding: 7px 13px; }
    body.pdf section#main .pages .page.last section,
    body.complete section#main .pages .page.last section {
      padding: 50px 50px; }
      body.pdf section#main .pages .page.last section .comments,
      body.complete section#main .pages .page.last section .comments {
        z-index: 10; }
        body.pdf section#main .pages .page.last section .comments .comment,
        body.complete section#main .pages .page.last section .comments .comment {
          position: relative;
          border: 2px solid #53BE4D;
          padding: 20px 30px;
          margin-bottom: 30px;
          width: 60%;
          font-size: 12px;
          background: #fff; }
          body.pdf section#main .pages .page.last section .comments .comment:after,
          body.complete section#main .pages .page.last section .comments .comment:after {
            content: '';
            position: absolute;
            bottom: -15px;
            right: -15px;
            background: #0097DC;
            width: 100%;
            height: 100%;
            z-index: -1; }
          body.pdf section#main .pages .page.last section .comments .comment:nth-child(3),
          body.complete section#main .pages .page.last section .comments .comment:nth-child(3) {
            margin-left: 20%; }
          body.pdf section#main .pages .page.last section .comments .comment h3,
          body.complete section#main .pages .page.last section .comments .comment h3 {
            border-bottom: 1px solid #2D3844 !important;
            margin-bottom: 10px;
            padding-bottom: 10px; }

body.extra-questions section#main {
  -ms-flex-align: normal;
      align-items: normal;
  padding: 75px;
  display: block; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body.extra-questions section#main {
      padding: 75px 30px; } }

body.extra-questions .questions.ui.container {
  position: relative; }
  body.extra-questions .questions.ui.container .dots {
    position: absolute;
    top: -90px;
    left: -200px;
    z-index: -1; }

body.extra-questions .questions-wrapper {
  padding: 0px 0px 0px 0px !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body.extra-questions .questions-wrapper {
      padding: 60px 50px 60px 50px !important; } }
  body.extra-questions .questions-wrapper h1 {
    font-size: 40px;
    margin-bottom: 70px;
    margin-top: 50px; }
  body.extra-questions .questions-wrapper .value_range {
    background: #fff;
    padding: 50px 50px 50px 40px;
    border-width: 2px 2px 2px 2px !important;
    border-color: #2D3844;
    border-style: solid;
    position: relative;
    z-index: 100;
    box-shadow: 15px 15px 0px 0px #2d3844;
    margin-bottom: 50px; }
    body.extra-questions .questions-wrapper .value_range header {
      margin-bottom: 50px;
      position: relative; }
      body.extra-questions .questions-wrapper .value_range header:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0px;
        height: 2px;
        width: 700px;
        background: #2D3844; }
    body.extra-questions .questions-wrapper .value_range .ui.radio {
      margin-right: 0px; }
      body.extra-questions .questions-wrapper .value_range .ui.radio label {
        position: relative;
        width: 170px;
        height: 30px;
        padding: 10px 0px 0px 40px;
        font-size: 14px;
        font-weight: bold; }
        body.extra-questions .questions-wrapper .value_range .ui.radio label:before {
          border-radius: 0px;
          width: 30px;
          height: 30px;
          border-width: 2px;
          border-color: #2D3844; }
        body.extra-questions .questions-wrapper .value_range .ui.radio label:after {
          position: absolute;
          top: 8px;
          left: 11px;
          background-color: transparent;
          content: '';
          width: 8px;
          height: 16px;
          border-radius: 0px;
          border: solid #000;
          border-width: 0 4px 4px 0;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
  body.extra-questions .questions-wrapper .textarea {
    position: relative;
    z-index: 100;
    margin-top: 40px;
    margin-bottom: 50px; }
    body.extra-questions .questions-wrapper .textarea header {
      position: absolute;
      top: 40px;
      left: 40px;
      width: 100%; }
      body.extra-questions .questions-wrapper .textarea header:after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0px;
        height: 2px;
        width: 700px;
        background: #2D3844; }
    body.extra-questions .questions-wrapper .textarea textarea {
      border: 2px solid transparent;
      border-top-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom: 3px solid #2D3844;
      background: transparent;
      width: 100%;
      font-size: 18px;
      padding: 20px 250px 20px 20px;
      resize: none;
      line-height: 1.5;
      overflow: hidden; }
      body.extra-questions .questions-wrapper .textarea textarea:focus, body.extra-questions .questions-wrapper .textarea textarea:hover, body.extra-questions .questions-wrapper .textarea textarea:active {
        outline: 0; }
      body.extra-questions .questions-wrapper .textarea textarea::-webkit-input-placeholder {
        color: rgba(45, 56, 68, 0.3); }
      body.extra-questions .questions-wrapper .textarea textarea::-moz-placeholder {
        color: rgba(45, 56, 68, 0.3); }
      body.extra-questions .questions-wrapper .textarea textarea:-ms-input-placeholder {
        color: rgba(45, 56, 68, 0.3); }
      body.extra-questions .questions-wrapper .textarea textarea:-moz-placeholder {
        color: rgba(45, 56, 68, 0.3); }
    body.extra-questions .questions-wrapper .textarea .ui.basic.button {
      border: 2px solid #2D3844;
      color: #2D3844;
      border-radius: 33px;
      padding: 18px 20px;
      min-width: 190px;
      min-height: 66px;
      font-size: 20px;
      font-weight: bold;
      box-shadow: 0px 0px 0px #000;
      position: absolute;
      bottom: 25px;
      right: 30px;
      -webkit-transition: 100ms all ease;
      transition: 100ms all ease; }
      body.extra-questions .questions-wrapper .textarea .ui.basic.button:active, body.extra-questions .questions-wrapper .textarea .ui.basic.button:focus {
        background: transparent !important; }
      body.extra-questions .questions-wrapper .textarea .ui.basic.button:hover {
        background-color: #2D3844;
        color: #fff; }
    body.extra-questions .questions-wrapper .textarea .clear {
      display: none;
      position: absolute;
      top: 19px;
      right: 24px;
      border: 2px solid transparent;
      padding: 8px 8px 5px; }
      body.extra-questions .questions-wrapper .textarea .clear:hover {
        border-radius: 50%;
        border: 2px solid #2D3844;
        cursor: pointer; }
    body.extra-questions .questions-wrapper .textarea.done:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: #2D3844;
      z-index: -1;
      -webkit-transform: translate3D(15px, 15px, -1px);
              transform: translate3D(15px, 15px, -1px); }
    body.extra-questions .questions-wrapper .textarea.done textarea {
      background: #fff;
      padding: 110px 50px 50px 40px;
      border-width: 2px 2px 2px 2px !important;
      border-color: #2D3844; }
    body.extra-questions .questions-wrapper .textarea.done .ui.basic.button {
      border: 0px solid #2D3844 !important;
      font-size: 14px;
      bottom: auto;
      top: 28px;
      right: 72px;
      min-width: 50px;
      min-height: 14px;
      padding: 0px;
      margin: 0px;
      -webkit-transition: 0ms all ease;
      transition: 0ms all ease; }
      body.extra-questions .questions-wrapper .textarea.done .ui.basic.button:hover {
        background-color: transparent !important;
        color: #2D3844;
        text-decoration: underline; }
    body.extra-questions .questions-wrapper .textarea.done .clear {
      display: block; }
  body.extra-questions .questions-wrapper .link-wrapper {
    opacity: 1;
    -webkit-transition: 500ms all ease;
    transition: 500ms all ease; }
    body.extra-questions .questions-wrapper .link-wrapper .action {
      margin-top: 50px; }
      body.extra-questions .questions-wrapper .link-wrapper .action svg {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
        body.extra-questions .questions-wrapper .link-wrapper .action svg g {
          opacity: 1; }
        body.extra-questions .questions-wrapper .link-wrapper .action svg circle {
          fill: #2D3844;
          stroke: #2D3844; }
        body.extra-questions .questions-wrapper .link-wrapper .action svg polyline {
          stroke: #fff; }

header#navigation {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 75px;
  width: 100%;
  background: #fff;
  box-shadow: 0 2px 14px 0 rgba(200, 200, 200, 0.5);
  z-index: 1000; }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    header#navigation {
      background: transparent;
      box-shadow: 0 0px 0px 0 rgba(200, 200, 200, 0);
      z-index: -1; } }
  header#navigation .container {
    position: relative; }
    header#navigation .container .ui.menu {
      margin-top: 40px;
      visibility: hidden; }
      header#navigation .container .ui.menu .item {
        -ms-flex: 1 1;
            flex: 1 1;
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: start;
            align-items: flex-start;
        margin: 0px 5px;
        font-family: "Lato", sans-serif !important;
        pointer-events: none; }
        header#navigation .container .ui.menu .item.active {
          font-weight: bold; }
        header#navigation .container .ui.menu .item .progress {
          width: 100%;
          margin-top: 15px;
          background: rgba(45, 56, 68, 0.1); }
          header#navigation .container .ui.menu .item .progress .bar {
            background: #969BA1;
            -webkit-transition: width .2s ease, background-color .2s ease;
            transition: width .2s ease, background-color .2s ease;
            min-width: 0px; }
      body.survey header#navigation .container .ui.menu {
        visibility: visible; }
    header#navigation .container .logo {
      position: absolute;
      top: -36px;
      left: 0%;
      -webkit-transform: translateX(0%);
              transform: translateX(0%); }
      header#navigation .container .logo svg {
        height: auto;
        width: 100px; }
      @media screen and (max-width: 1600px) {
        header#navigation .container .logo {
          top: -40px;
          left: 0px; } }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        header#navigation .container .logo {
          z-index: -1;
          left: auto;
          right: 0px;
          -webkit-transform: translateZ(-1);
                  transform: translateZ(-1); } }

body.survey header#navigation .logo {
  display: none; }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  body.survey header#navigation {
    background: #fff !important;
    box-shadow: 0 2px 14px 0 rgba(200, 200, 200, 0.5);
    z-index: 999;
    height: 70px; } }

body.survey header#navigation .ui.menu {
  margin-top: 20px; }

.pages .page {
  width: 29.7cm;
  min-height: 21cm;
  padding: 0px;
  margin: 1cm auto;
  border: 1px #D3D3D3 solid !important;
  border-radius: 0px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
  .pages .page header {
    border-bottom: 2px solid #53BE4D; }
    .pages .page header .column.title {
      height: 135px;
      padding: 50px 60px; }
    .pages .page header .column.number {
      padding: 0px 30px 0px 0px; }
      .pages .page header .column.number span {
        border-radius: 50%;
        border: 2px solid #2D3844 !important;
        padding: 20px 18px;
        font-size: 22px;
        font-weight: bold; }
  .pages .page.first section {
    padding: 50px 20px; }
    .pages .page.first section .ui.container.score {
      padding: 80px 50px 50px; }
      .pages .page.first section .ui.container.score .column {
        padding: 0px 7px; }
        .pages .page.first section .ui.container.score .column .range {
          height: 9px;
          border-radius: 5px;
          padding: 0px; }
          .pages .page.first section .ui.container.score .column .range.bad {
            background: #C31A48; }
          .pages .page.first section .ui.container.score .column .range.okay {
            background: #F5C301; }
          .pages .page.first section .ui.container.score .column .range.great {
            background: #53BE4D; }
      .pages .page.first section .ui.container.score .marker-wrapper {
        position: relative;
        margin: 0px 3px; }
        .pages .page.first section .ui.container.score .marker-wrapper .marker {
          position: absolute;
          top: -63px;
          left: 70%;
          width: 40px;
          height: 40px;
          background: #2D3844;
          text-align: center;
          padding: 10px 0px;
          border-radius: 50%;
          font-weight: bold;
          font-size: 16px;
          color: #fff;
          -webkit-transform: translateX(-20px);
                  transform: translateX(-20px);
          z-index: 30; }
          .pages .page.first section .ui.container.score .marker-wrapper .marker .arrow-down {
            position: absolute;
            bottom: -6px;
            left: 50%;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 8px solid transparent !important;
            border-right: 8px solid transparent !important;
            border-top: 10px solid #f00 !important; }
          .pages .page.first section .ui.container.score .marker-wrapper .marker.green .arrow-down {
            border-top: 10px solid #53BE4D !important; }
          .pages .page.first section .ui.container.score .marker-wrapper .marker.yellow .arrow-down {
            border-top: 10px solid #F5C301 !important; }
          .pages .page.first section .ui.container.score .marker-wrapper .marker.red .arrow-down {
            border-top: 10px solid #C31A48 !important; }
        .pages .page.first section .ui.container.score .marker-wrapper .number {
          position: absolute;
          top: -53px;
          left: 70%;
          font-size: 18px;
          font-weight: bold;
          -webkit-transform: translateX(-5px);
                  transform: translateX(-5px);
          z-index: 10; }
        .pages .page.first section .ui.container.score .marker-wrapper .number-1 {
          left: 0%; }
        .pages .page.first section .ui.container.score .marker-wrapper .number-3 {
          left: 33.3%;
          display: none; }
        .pages .page.first section .ui.container.score .marker-wrapper .number-4 {
          left: 66.6%;
          display: none; }
        .pages .page.first section .ui.container.score .marker-wrapper .number-5 {
          left: 100%; }
    .pages .page.first section .ui.container.summary .column:first-of-type {
      border-right: 1px solid rgba(45, 56, 68, 0.1) !important;
      padding-right: 30px; }
    .pages .page.first section .ui.container.summary .column:last-of-type {
      padding-right: 30px; }
    .pages .page.first section .ui.container.summary table thead tr {
      margin-bottom: 20px; }
      .pages .page.first section .ui.container.summary table thead tr th {
        font-size: 30px;
        font-weight: bold;
        border-bottom-width: 2px;
        padding: 30px 0px; }
    .pages .page.first section .ui.container.summary table tbody tr {
      border-bottom: 0px solid #2D3844 !important; }
      .pages .page.first section .ui.container.summary table tbody tr:first-child td {
        padding-top: 0px; }
      .pages .page.first section .ui.container.summary table tbody tr td {
        border-top-width: 0px;
        padding-bottom: 30px; }
        .pages .page.first section .ui.container.summary table tbody tr td:first-child {
          padding-left: 25px; }
        .pages .page.first section .ui.container.summary table tbody tr td.number {
          font-size: 16px;
          font-weight: bold; }
        .pages .page.first section .ui.container.summary table tbody tr td.question {
          padding-left: 0px; }
        .pages .page.first section .ui.container.summary table tbody tr td.answer span {
          font-size: 18px;
          font-weight: bold;
          border: 2px solid #2D3844 !important;
          border-radius: 50%;
          width: 37px;
          height: 37px;
          padding: 7px 13px; }
  .pages .page.last section {
    padding: 50px 50px; }
    .pages .page.last section .comments {
      z-index: 10; }
      .pages .page.last section .comments .comment {
        position: relative;
        border: 2px solid #53BE4D;
        padding: 20px 30px;
        margin-bottom: 30px;
        width: 60%;
        font-size: 12px;
        background: #fff; }
        .pages .page.last section .comments .comment:after {
          content: '';
          position: absolute;
          bottom: -15px;
          right: -15px;
          background: #0097DC;
          width: 100%;
          height: 100%;
          z-index: -1; }
        .pages .page.last section .comments .comment:nth-child(3) {
          margin-left: 20%; }
        .pages .page.last section .comments .comment h3 {
          border-bottom: 1px solid #2D3844 !important;
          margin-bottom: 10px;
          padding-bottom: 10px; }

*:not(.icon):not(button) {
  font-family: 'Montserrat', sans-serif !important;
  color: #2D3844; }

button {
  font-family: "Lato", sans-serif !important; }

html,
body {
  height: 100%;
  width: 100%;
  color: #2D3844;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  html #root,
  body #root {
    height: 100%; }

a:hover, a:active, a:focus {
  outline: 0 !important; }

.loader-container {
  height: 100%;
  opacity: 0;
  -webkit-transition: 500ms all ease;
  transition: 500ms all ease;
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none; }
  .loader-container.active {
    opacity: 1;
    -webkit-transition: 0ms all ease;
    transition: 0ms all ease; }
  .loader-container.clickable {
    pointer-events: all; }
  .loader-container .grid {
    height: 100%; }

section#main {
  position: relative;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

p {
  font-size: 16px;
  line-height: 25px; }

.ui.popup {
  border-radius: 0px;
  border: 2px solid #0097DC;
  background: #fff;
  box-shadow: 0px 0px 0px #000; }
  .ui.popup:before {
    box-shadow: 2px 2px 0 0 #0097DC; }

.ui.main-container {
  height: 100%; }

.fp-enabled .ui.main-container {
  height: auto; }

.ui.basic.button {
  border: 2px solid #F5C301;
  border-radius: 25px;
  color: #F5C301 !important;
  box-shadow: 0px 0px 0px 0px black;
  font-size: 20px;
  font-family: "Lato", sans-serif !important;
  padding: 13px 30px; }
  .ui.basic.button:hover, .ui.basic.button.active {
    box-shadow: 0px 0px 0px 0px black;
    background: #F5C301 !important;
    color: #fff !important;
    font-weight: bold; }
  .ui.basic.button.default {
    color: #2D3844 !important;
    border: 2px solid #2D3844; }
    .ui.basic.button.default:hover, .ui.basic.button.default.active {
      box-shadow: 0px 0px 0px 0px black;
      background: #2D3844 !important;
      color: #fff !important;
      font-weight: bold; }

.navigation-box {
  position: relative;
  text-align: left;
  padding-left: 50px !important; }
  .navigation-box .wrapper {
    position: relative;
    display: inline-block; }
    .navigation-box .wrapper h3 {
      font-size: 24px;
      font-family: "Lato", sans-serif !important;
      position: relative;
      display: block;
      margin-bottom: 5px; }
    .navigation-box .wrapper span {
      font-size: 18px;
      font-family: "Lato", sans-serif !important;
      display: block; }
    .navigation-box .wrapper svg {
      position: absolute;
      top: 0px;
      left: 150%;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
      .navigation-box .wrapper svg circle {
        stroke: #878D94;
        -webkit-transition: 200ms all ease;
        transition: 200ms all ease; }
      .navigation-box .wrapper svg polyline {
        stroke: #878D94;
        -webkit-transition: 200ms all ease;
        transition: 200ms all ease; }
      .navigation-box .wrapper svg g {
        opacity: 1; }
  .navigation-box a {
    position: absolute;
    display: block;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: -100px;
    z-index: 100; }
  html.no-touch .navigation-box:hover {
    cursor: pointer; }
    html.no-touch .navigation-box:hover svg circle {
      stroke: #0097DC;
      fill: #0097DC; }
    html.no-touch .navigation-box:hover svg polyline {
      stroke: #fff; }
  .navigation-box.up svg {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important; }
  .navigation-box.down svg {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); }
  .navigation-box.box-left {
    text-align: right;
    padding-right: 50px !important; }
    .navigation-box.box-left svg {
      right: 150%;
      left: auto;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
    .navigation-box.box-left a {
      left: -100px; }
  .navigation-box.box-left.up svg {
    right: 150%;
    left: auto;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  .navigation-box.filled svg circle {
    stroke: #0097DC;
    fill: #0097DC; }
  .navigation-box.filled svg polyline {
    stroke: #fff; }

