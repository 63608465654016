body.extra-questions {

    section#main {
        align-items: normal;
        padding: $navigation_height;
        display: block;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            padding: $navigation_height 30px;
        }
    }

    .questions.ui.container {
        position: relative;

        .dots {
            position: absolute;
            top: -90px;
            left: -200px;
            z-index: -1;

        }
    }


    .questions-wrapper {
        padding: 0px 0px 0px 0px !important;
        margin-top: 30px !important;
        margin-bottom: 50px !important;
        //background: $white;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            padding: 60px 50px 60px 50px !important;
        }

        h1 {
            font-size: 40px;
            margin-bottom: 70px;
            margin-top: 50px;
        }

        .value_range {
            background: $white;
            padding: 50px 50px 50px 40px;
            border-width: 2px 2px 2px 2px !important;
            border-color: $black;
            border-style: solid;
            position: relative;
            z-index: 100;
            box-shadow: 15px 15px 0px 0px rgba($color: $black, $alpha: 1.0);
            margin-bottom: 50px;

            header {
                margin-bottom: 50px;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    left: 0px;
                    height: 2px;
                    width: 700px;
                    background: $black;
                }
            }

            .ui.radio {
                margin-right: 0px;

                label {
                    position: relative;
                    width: 170px;
                    height: 30px;
                    padding: 10px 0px 0px 40px;
                    font-size: 14px;
                    font-weight: bold;

                    &:before {
                        border-radius: 0px;
                        width: 30px;
                        height: 30px;
                        border-width: 2px;
                        border-color: $black;
                    }

                    &:after {
                        position: absolute;
                        top: 8px;
                        left: 11px;
                        background-color: transparent;
                        content: '';
                        width: 8px;
                        height: 16px;
                        border-radius: 0px;
                        border: solid #000;
                        border-width: 0 4px 4px 0;
                        transform: rotate(45deg);
                    }
                }
            }

        }

        .textarea {
            position: relative;
            z-index: 100;
            margin-top: 40px;
            margin-bottom: 50px;

            header {
                position: absolute;
                top: 40px;
                left: 40px;
                width: 100%;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    left: 0px;
                    height: 2px;
                    width: 700px;
                    background: $black;
                }
            }

            textarea {
                border: 2px solid transparent;
                border-top-color: transparent;
                border-left-color: transparent;
                border-right-color: transparent;
                border-bottom: 3px solid $black;
                background: transparent;
                width: 100%;
                font-size: 18px;
                padding: 20px 250px 20px 20px;
                resize: none;
                line-height: 1.5;
                //transition: 200ms all ease;
                overflow: hidden;

                &:focus,
                &:hover,
                &:active {
                    outline: 0;
                }

                &::-webkit-input-placeholder {
                    color: rgba($color: $black, $alpha: 0.3);
                }

                &::-moz-placeholder {
                    color: rgba($color: $black, $alpha: 0.3);
                }

                &:-ms-input-placeholder {
                    color: rgba($color: $black, $alpha: 0.3);
                }

                &:-moz-placeholder {
                    color: rgba($color: $black, $alpha: 0.3);
                }
            }

            .ui.basic.button {
                border: 2px solid $black;
                color: $black;
                border-radius: 33px;
                padding: 18px 20px;
                min-width: 190px;
                min-height: 66px;
                font-size: 20px;
                font-weight: bold;
                box-shadow: 0px 0px 0px #000;
                position: absolute;
                bottom: 25px;
                right: 30px;
                transition: 100ms all ease;

                &:active,
                &:focus {
                    background: transparent !important;
                }

                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }

            .clear {
                display: none;
                position: absolute;
                top: 19px;
                right: 24px;
                border: 2px solid transparent;
                padding: 8px 8px 5px;

                &:hover {
                    border-radius: 50%;
                    border: 2px solid $black;
                    cursor: pointer;
                }
            }

            &.done {
                &:after {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    background: $black;
                    z-index: -1;
                    transform: translate3D(15px, 15px, -1px);
                }

                textarea {
                    background: $white;
                    padding: 110px 50px 50px 40px;
                    border-width: 2px 2px 2px 2px !important;
                    border-color: $black;
                }

                .ui.basic.button {
                    border: 0px solid $black !important;
                    font-size: 14px;
                    bottom: auto;
                    top: 28px;
                    right: 72px;
                    min-width: 50px;
                    min-height: 14px;
                    padding: 0px;
                    margin: 0px;
                    transition: 0ms all ease;

                    &:hover {
                        background-color: transparent !important;
                        color: $black;
                        text-decoration: underline;
                    }
                }

                .clear {
                    display: block;
                }
            }
        }

        .link-wrapper {
            opacity: 1;
            transition: 500ms all ease;

            .action {
                margin-top: 50px;

                svg {
                    transform: rotate(180deg);

                    g {
                        opacity: 1;
                    }

                    circle {
                        fill: $black;
                        stroke: $black;

                    }

                    polyline {
                        stroke: $white;
                    }
                }
            }
        }
    }
}