body.pdf,
body.complete {

    section#main {
        align-items: normal;
        padding: $navigation_height + 50px;
        display: block;

        .pages {

            .page {
                width: 29.7cm;
                min-height: 21cm;
                padding: 0px;
                margin: 1cm auto;
                border: 1px #D3D3D3 solid !important;
                border-radius: 0px;
                background: white;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
                position: relative;

                .logo {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                }

                header {
                    border-bottom: 2px solid $green;

                    .column {
                        &.title {
                            height: 135px;
                            padding: 50px 60px;
                        }

                        &.text {}

                        &.number {
                            padding: 0px 30px 0px 0px;

                            span {
                                border-radius: 50%;
                                border: 2px solid $black !important;
                                padding: 20px 18px;
                                font-size: 22px;
                                font-weight: bold;
                            }
                        }
                    }
                }

                &.first {
                    section {
                        padding: 50px 20px;

                        .ui.container {

                            &.score {
                                padding: 80px 50px 50px;

                                .column {
                                    padding: 0px 7px;

                                    .range {
                                        height: 9px;
                                        border-radius: 5px;
                                        padding: 0px;

                                        &.bad {
                                            background: $red;
                                        }

                                        &.okay {
                                            background: $yellow;
                                        }

                                        &.great {
                                            background: $green;
                                        }
                                    }
                                }

                                .marker-wrapper {
                                    position: relative;
                                    margin: 0px 3px;

                                    .marker {
                                        position: absolute;
                                        top: -63px;
                                        left: 70%;
                                        width: 40px;
                                        height: 40px;
                                        background: $black;
                                        text-align: center;
                                        padding: 10px 0px;
                                        border-radius: 50%;
                                        font-weight: bold;
                                        font-size: 16px;
                                        color: $white;
                                        transform: translateX(-20px);
                                        z-index: 30;

                                        .arrow-down {
                                            position: absolute;
                                            bottom: -6px;
                                            left: 50%;
                                            transform: translateX(-50%);
                                            width: 0;
                                            height: 0;
                                            border-left: 8px solid transparent !important;
                                            border-right: 8px solid transparent !important;
                                            border-top: 10px solid #f00 !important;
                                        }

                                        &.green {
                                            .arrow-down {
                                                border-top: 10px solid $green !important;
                                            }
                                        }

                                        &.yellow {
                                            .arrow-down {
                                                border-top: 10px solid $yellow !important;
                                            }
                                        }

                                        &.red {
                                            .arrow-down {
                                                border-top: 10px solid $red !important;
                                            }
                                        }
                                    }

                                    .number {
                                        position: absolute;
                                        top: -53px;
                                        left: 70%;
                                        font-size: 18px;
                                        font-weight: bold;
                                        transform: translateX(-5px);
                                        z-index: 10;
                                    }

                                    .number-1 {
                                        left: 0%;
                                    }

                                    .number-3 {
                                        left: 33.3%;
                                        display: none;
                                    }

                                    .number-4 {
                                        left: 66.6%;
                                        display: none;
                                    }

                                    .number-5 {
                                        left: 100%;
                                    }
                                }
                            }



                            &.summary {
                                .column:first-of-type {
                                    border-right: 1px solid rgba($color: #2D3844, $alpha: 0.1) !important;
                                    padding-right: 30px;
                                }

                                .column:last-of-type {
                                    padding-right: 30px;
                                }

                                table {
                                    thead {
                                        tr {
                                            th {
                                                font-size: 30px;
                                                font-weight: bold;
                                                border-bottom-width: 2px;
                                                padding: 30px 0px;
                                            }

                                            margin-bottom: 20px;
                                        }
                                    }

                                    tbody {
                                        tr {
                                            border-bottom: 0px solid $black !important;

                                            &:first-child {
                                                td {
                                                    padding-top: 0px;
                                                }
                                            }

                                            td {
                                                border-top-width: 0px;
                                                padding-bottom: 30px;

                                                &:first-child {
                                                    padding-left: 25px;
                                                }

                                                &.number {
                                                    font-size: 16px;
                                                    font-weight: bold;
                                                }

                                                &.question {
                                                    padding-left: 0px;
                                                }

                                                &.answer {
                                                    span {
                                                        font-size: 18px;
                                                        font-weight: bold;
                                                        border: 2px solid $black !important;
                                                        border-radius: 50%;
                                                        width: 37px;
                                                        height: 37px;
                                                        padding: 7px 13px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }

                &.last {
                    section {
                        padding: 50px 50px;

                        .comments {
                            z-index: 10;

                            .comment {
                                position: relative;
                                border: 2px solid $green;
                                padding: 20px 30px;
                                margin-bottom: 30px;
                                width: 60%;
                                font-size: 12px;
                                background: $white;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: -15px;
                                    right: -15px;
                                    background: $blue;
                                    width: 100%;
                                    height: 100%;
                                    z-index: -1;
                                }

                                &:nth-child(3) {
                                    margin-left: 20%;
                                }

                                h3 {
                                    border-bottom: 1px solid $black !important;
                                    margin-bottom: 10px;
                                    padding-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}