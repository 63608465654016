body.questions {

    section#main {
        align-items: normal;
        padding: $navigation_height;
        display: block;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            padding: $navigation_height 30px;
        }
    }

    .questions.ui.container {
        position: relative;

        .dots {
            position: absolute;
            top: -90px;
            left: -200px;
            z-index: -1;

        }
    }


    .questions-wrapper {
        border: 2px solid $yellow;
        box-shadow: 15px 15px 0px $yellow;
        padding: 60px 100px 60px 100px !important;
        margin-top: 30px !important;
        margin-bottom: 50px !important;
        background: $white;

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            padding: 60px 50px 60px 50px !important;
        }

        h1 {
            font-size: 40px;
            margin-bottom: 70px;
        }

        .group {
            h4 {
                font-size: 24px;
                font-family: $lato;
                margin-bottom: 30px;
            }

            .ui.basic.button {
                margin-bottom: 50px;
                margin-right: 30px;
                min-width: 150px;
            }

            .dropdown-wrapper {
                position: relative;

                .ui.upward.selection.dropdown {
                    &:hover {
                        box-shadow: 0px 0px 0px 0px rgba($color: #000000, $alpha: 0)
                    }
                }

                .ui.dropdown {
                    border-width: 0px 0px 2px 0px;
                    border-radius: 0px;
                    border-color: $yellow;


                    .icon.dropdown {
                        display: none;
                    }

                    &.active {
                        box-shadow: 0px 0px 0px #000;
                    }

                    .text {
                        font-size: 20px;
                    }

                    .menu {
                        border: 2px solid $yellow;
                        box-shadow: 0px 0px 0px #000;
                        border-radius: 0px;
                        border-top-width: 2px !important;

                        .item {
                            background: $white;
                            border: 0px solid #000;

                            span {
                                font-family: $lato;
                                font-size: 16px;
                            }

                            &:hover {
                                background: $yellow;
                                color: $white;

                                span {
                                    color: $white;
                                }
                            }

                            &.selected {
                                background: $yellow;
                                color: $white;

                                span {
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                svg {
                    position: absolute;
                    top: 15px;
                    right: 30px;
                    z-index: 100;
                    pointer-events: none;
                }
            }
        }

        .company-size {}

        .type {}

        .link-wrapper {
            opacity: 0.3;
            transition: 500ms all ease;
            pointer-events: none;

            &.active {
                pointer-events: initial;
                opacity: 1;

                &:hover {
                    cursor: pointer;
                }
            }

            .action {
                margin-top: 50px;

                svg {
                    transform: rotate(180deg);

                    g {
                        opacity: 1;
                    }

                    circle {
                        fill: $yellow;
                        stroke: $yellow;

                    }

                    polyline {
                        stroke: $white;
                    }
                }
            }
        }
    }
}