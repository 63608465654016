body.survey {

    @media screen and (max-height: 850px) {

        section#main {
            .fp-tableCell {
                padding-top: 0px;
            }
        }
    }

    @media screen and (max-height: 600px) {
        section#main {
            .fp-tableCell {
                padding-top: 50px;
            }
        }
    }

    @media screen and (max-height: 400px) {
        section#main {
            .fp-tableCell {
                padding-top: 100px;
            }
        }
    }

    .background-drop {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        display: flex;
        align-items: center;

        $speed_transition: 550ms;
        $speed_transition2: 550ms;

        .ui.container {
            width: 1090px !important;
            height: 390px;
            position: relative;

            .dots {
                position: absolute;
                top: -230px;
                left: -150px;
                z-index: 120;
                //transition: $speed_transition2 all ease-out;
            }

            .square {
                position: absolute;
                // top: -140px;
                // left: -60px;
                width: 300px;
                height: 300px;
                background: $lightblue;
                z-index: 100;
                //transition: $speed_transition2 all ease;
                transform-origin: 100% 50%;
                transform: translate(-60px, -140px);
                will-change: transform;
            }
        }

        &.start-overlay {
            .ui.container {
                .dots {
                    position: absolute;
                    top: -230px;
                    left: 360px;
                    transform: scale(1.5);
                    transition: $speed_transition all ease;


                }

                .square {
                    // position: absolute;
                    // top: -140px;
                    // left: 160px;
                    transition: $speed_transition all ease;
                    transform: scale(6) translate(29px, 0%);

                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                        transform: scale(6) translate(23px, 0%);
                    }
                }
            }
        }
    }

    .end-overlay {
        .sidebar-back {
            width: 100%;
            transition: 500ms all ease;
        }
    }

    .sidebar-back {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 300px;
        height: 100%;
        background: $lightblue;
        transition: 500ms all ease;
        transform-style: preserve-3d;
        z-index: 200;
        transition: 500ms all ease;

        @media screen and (max-width: 1800px) {
            width: 200px;
        }

        &.hide {
            transform: translateX(100%);
        }

        &:after {
            content: '';
            position: absolute;
            background: #fff;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            z-index: -1;
            transform: translateZ(-1px);
        }
    }

    .sidebar {
        position: fixed;
        top: 0px;
        right: 10px;
        width: 300px;
        height: 100%;
        z-index: 50;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 500ms all ease;

        @media screen and (max-width: 1800px) {
            width: 200px;
        }

        &.hide {
            transform: translateX(100%);
        }

        #menu {
            ul {
                position: absolute;
                top: 50%;
                left: calc(50% + 10px);
                transform: translate(-50%, -50%);
                list-style: none;
                padding: 0px;
                transition: 500ms all ease;
                z-index: -1;

                &.show {
                    opacity: 1;
                    z-index: 100;
                }

                li {
                    a {
                        line-height: 0;
                        font-size: 0;
                        color: transparent;
                        width: 14px;
                        height: 14px;
                        background: $white;
                        display: block;
                        border-radius: 50%;
                        border: 1px solid $green;
                        margin: 18px 0px;
                        transition: 700ms all ease;
                    }

                    &.active {
                        a {
                            height: 40px;
                            background: $green;
                            border-radius: 14px;
                        }
                    }
                }
            }
        }
    }

    #fullpage {
        //top: -$navigation_height;

        transform: translatez(0);

        .section {
            opacity: 0.03;
            transition: 500ms ease all;
            border-top: 0px solid #000;
            border-bottom: 0px solid #000;

            .scroll-wrapper {
                height: calc(100% - 70px);
                overflow-y: scroll;
                overflow-x: hidden;
            }

            &.fp-completely {
                .ui.container.bottom {
                    &:after {
                        display: none;
                    }
                }
            }

            &.active {
                opacity: 1;
            }

            .ui.container {
                width: 1090px !important;

                @media screen and (max-width: 1550px) {

                    &.top,
                    &.bottom {
                        width: 800px !important;
                        transform: translateX(-100px);
                    }

                }

                @media screen and (max-width: 1250px) {


                    width: 100% !important;

                    &.top,
                    &.bottom {
                        width: 700px !important;
                        transform: translateX(-100px);
                    }
                }




            }

            &.start {
                h1 {
                    font-size: 40px;
                }

                .column:last-of-type {
                    padding-left: 50px;
                    padding-right: 50px;
                }

                .huset {
                    padding-left: 50px;

                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                        img {
                            width: 90%;
                        }
                    }
                }

                section.bottom-navigation {
                    min-height: auto;
                    height: auto;
                    padding: 100px 0px 0px;

                    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                        .two.column {
                            padding: 0px 60px !important;
                        }
                    }
                }
            }


            &.question {
                .top {
                    &>.ui.grid {
                        border-bottom: 2px solid $blue;
                    }

                    .number {
                        font-size: 100px;
                        font-weight: bold;
                        line-height: 100px;

                        @media screen and (max-height: 600px) {
                            font-size: 50px;
                            line-height: 50px;
                        }
                    }

                    h2 {
                        font-size: 30px;
                        font-weight: bold;

                        @media screen and (max-height: 600px) {
                            font-size: 20px;
                        }
                    }
                }

                .bottom {

                    &:after {
                        content: '';
                        background: transparent;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                    }

                    .sixteen {
                        padding-left: 0px !important;
                        padding-right: 0px !important;
                    }

                    ul {
                        list-style: none;
                        padding: 0px;



                        li {
                            html.no-touch & {
                                &:hover {
                                    .ui.grid {
                                        background: $blue;

                                        .dash {
                                            &:after {
                                                background: $white;
                                            }
                                        }

                                        .number {
                                            border: 3px solid $white;
                                        }

                                        .text,
                                        h4 {
                                            color: $white;
                                        }
                                    }
                                }
                            }

                            &.active {
                                .ui.grid {
                                    background: $blue;

                                    .dash {
                                        &:after {
                                            background: $white;
                                        }
                                    }

                                    .number {
                                        border: 3px solid $white;
                                    }

                                    .text,
                                    h4 {
                                        color: $white;
                                    }
                                }
                            }

                            will-change: opacity;

                            .ui.grid {
                                margin: 0px 0px;
                                padding: 10px 0px;
                                transition: 200ms all ease;

                                @media screen and (max-height: 600px) {
                                    padding: 5px 0px;
                                }

                                .text {
                                    @media screen and (max-height: 600px) {
                                        font-size: 14px;
                                    }

                                    @media screen and (max-width: 1250px) {
                                        width: 70% !important;
                                    }
                                }
                            }



                            &.active {
                                @keyframes flash {
                                    50% {
                                        opacity: 0.5;
                                    }
                                }

                                .ui.grid {
                                    background: $blue;
                                }

                                animation: 0.25s ease 0s 2 normal none running flash;
                            }

                            .tast {
                                color: $white;
                                padding-right: 0px !important;
                                width: 50px !important;
                            }

                            .number {
                                width: 37px !important;
                                height: 37px;
                                border-radius: 50%;
                                border: 2px solid $blue;
                                position: relative;

                                h4 {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    font-size: 18px;
                                }
                            }

                            .dash {
                                position: relative;
                                margin: 0px 20px 0px 0px;
                                width: 30px !important;
                                padding: 0px !important;

                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 45%;
                                    left: 50%;
                                    width: 17px;
                                    height: 3px;
                                    background: $blue;
                                }
                            }

                            .text {
                                padding: 0px 0px !important;
                                font-size: 18px;
                            }
                        }
                    }
                }
            }

            &.summary {
                padding-top: $navigation_height;
                margin-bottom: $navigation_height;
                display: block;
                table-layout: auto;

                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                    padding-top: 70px;
                }

                section.top-navigation {
                    padding: 50px 0px;

                }

                .fp-tableCell {
                    display: block;
                }

                .summary-list {
                    padding: 40px 0px 0px;

                    @media screen and (max-width: 1050px) {
                        width: 70% !important;
                        transform: translateX(-90px);
                    }

                    @media screen and (max-width: 1500px) {
                        width: 70% !important;
                        transform: translateX(-90px);
                    }
                }

                table {
                    thead {
                        tr {
                            th {
                                font-size: 30px;
                                font-weight: bold;
                                border-bottom-width: 2px;
                                padding: 30px 0px;
                            }

                            margin-bottom: 20px;
                        }
                    }

                    tbody {
                        tr {
                            &:first-child {
                                td {
                                    padding-top: 50px;
                                }
                            }

                            td {
                                border-top-width: 0px;

                                &:first-child {
                                    padding-left: 25px;
                                }

                                &.number {
                                    font-size: 30px;
                                    font-weight: bold;
                                }

                                &.question {
                                    padding-left: 0px;
                                    font-size: 16px;
                                }

                                &.answer {
                                    span {
                                        font-size: 18px;
                                        font-weight: bold;
                                        border: 2px solid;
                                        border-radius: 50%;
                                        width: 37px;
                                        height: 37px;
                                        padding: 7px 13px;
                                    }
                                }
                            }
                        }
                    }

                }

                .bottom-navigation {
                    background: transparent;
                    margin-top: 100px;
                }
            }

            &.comments {
                padding-top: $navigation_height;
                margin-bottom: $navigation_height;
                display: block;
                table-layout: auto;

                section.top-navigation {
                    padding: 50px 0px;
                }

                @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                    padding-top: 70px;
                }

                .fp-tableCell {
                    //padding-top: $navigation_height;
                    //vertical-align: top;
                    display: block;

                }

                .comments {
                    @media screen and (max-width: 1050px) {
                        width: 70% !important;
                        transform: translateX(-90px);
                    }

                    @media screen and (max-width: 1500px) {
                        width: 70% !important;
                        transform: translateX(-90px);
                    }

                    padding-bottom: 115px;
                    padding-top: 30px;

                    .comment {
                        margin-bottom: 80px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .header {
                            padding: 0px 20px;

                            h3 {
                                font-size: 24px;
                                font-weight: bold;
                            }

                            p {
                                font-size: 20px;
                            }
                        }

                        .textarea {
                            position: relative;
                            z-index: 100;
                            margin-top: 40px;

                            textarea {
                                border: 2px solid transparent;
                                border-top-color: transparent;
                                border-left-color: transparent;
                                border-right-color: transparent;
                                border-bottom: 3px solid $blue;
                                background: transparent;
                                width: 100%;
                                font-size: 18px;
                                padding: 20px 250px 20px 20px;
                                resize: none;
                                line-height: 1.5;
                                //transition: 200ms all ease;
                                overflow: hidden;

                                &:focus,
                                &:hover,
                                &:active {
                                    outline: 0;
                                }

                                &::-webkit-input-placeholder {
                                    color: rgba($color: $black, $alpha: 0.3);
                                }

                                &::-moz-placeholder {
                                    color: rgba($color: $black, $alpha: 0.3);
                                }

                                &:-ms-input-placeholder {
                                    color: rgba($color: $black, $alpha: 0.3);
                                }

                                &:-moz-placeholder {
                                    color: rgba($color: $black, $alpha: 0.3);
                                }
                            }

                            .ui.basic.button {
                                border: 2px solid $blue;
                                color: $blue;
                                border-radius: 33px;
                                padding: 18px 20px;
                                min-width: 190px;
                                min-height: 66px;
                                font-size: 20px;
                                font-weight: bold;
                                box-shadow: 0px 0px 0px #000;
                                position: absolute;
                                bottom: 25px;
                                right: 30px;
                                transition: 100ms all ease;

                                &:active,
                                &:focus {
                                    background: transparent !important;
                                }

                                &:hover {
                                    background-color: $blue;
                                    color: $white;
                                }
                            }

                            .clear {
                                display: none;
                                position: absolute;
                                top: 19px;
                                right: 24px;
                                border: 2px solid transparent;
                                padding: 8px 8px 5px;

                                &:hover {
                                    border-radius: 50%;
                                    border: 2px solid $blue;
                                    cursor: pointer;
                                }
                            }

                            &.done {
                                &:after {
                                    content: '';
                                    position: absolute;
                                    top: 0px;
                                    left: 0px;
                                    right: 0px;
                                    bottom: 0px;
                                    background: $blue;
                                    z-index: -1;
                                    transform: translate3D(15px, 15px, -1px);
                                }

                                textarea {
                                    background: $white;
                                    padding: 80px 120px 80px 150px;
                                    border-width: 2px 2px 2px 2px !important;
                                    border-color: $blue;
                                }

                                .ui.basic.button {
                                    border: 0px solid $blue !important;
                                    font-size: 14px;
                                    bottom: auto;
                                    top: 28px;
                                    right: 72px;
                                    min-width: 50px;
                                    min-height: 14px;
                                    padding: 0px;
                                    margin: 0px;
                                    transition: 0ms all ease;

                                    &:hover {
                                        background-color: transparent !important;
                                        color: $blue;
                                        text-decoration: underline;
                                    }
                                }

                                .clear {
                                    display: block;
                                }
                            }
                        }

                    }
                }
            }

            section.bottom-navigation {
                padding: 50px 0px;
                background: $white;
                min-height: 220px;

                // a {
                //     padding-left: 70px;
                //     padding-right: 0px;
                // }
            }

        }
    }
}