*:not(.icon):not(button) {
    font-family: 'Montserrat', sans-serif !important;
    color: $black;
}

button {
    font-family: $lato;
}


html,
body {
    height: 100%;
    width: 100%;
    color: $black;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    #root {
        height: 100%;
    }
}

a {

    &:hover,
    &:active,
    &:focus {
        outline: 0 !important;
    }
}

// body {
//     //padding-top: $navigation_height !important;
// }

.loader-container {
    height: 100%;
    opacity: 0;
    transition: 500ms all ease;
    background: $white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    pointer-events: none;

    &.active {
        opacity: 1;
        transition: 0ms all ease;
    }

    &.clickable {
        pointer-events: all;
    }

    .grid {
        height: 100%;
    }
}

section#main {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


p {
    font-size: 16px;
    line-height: 25px;
}

.ui.popup {
    border-radius: 0px;
    border: 2px solid $blue;
    background: $white;
    box-shadow: 0px 0px 0px #000;

    &:before {
        box-shadow: 2px 2px 0 0 $blue;
    }
}

.ui.main-container {
    height: 100%;
}

.fp-enabled {
    .ui.main-container {
        height: auto;
    }
}

.ui.basic.button {

    border: 2px solid $yellow;
    border-radius: 25px;
    color: $yellow !important;
    box-shadow: 0px 0px 0px 0px rgba($color: #000000, $alpha: 1.0);
    font-size: 20px;
    font-family: $lato;
    padding: 13px 30px;

    &:hover,
    &.active {
        box-shadow: 0px 0px 0px 0px rgba($color: #000000, $alpha: 1.0);
        background: $yellow !important;
        color: $white !important;
        font-weight: bold;
    }

    &.default {
        color: $black !important;
        border: 2px solid $black;

        &:hover,
        &.active {
            box-shadow: 0px 0px 0px 0px rgba($color: #000000, $alpha: 1.0);
            background: $black !important;
            color: $white !important;
            font-weight: bold;
        }
    }
}



.navigation-box {
    position: relative;
    text-align: left;
    padding-left: 50px !important;



    .wrapper {
        position: relative;
        display: inline-block;

        h3 {
            font-size: 24px;
            font-family: $lato;
            position: relative;
            display: block;
            margin-bottom: 5px;
        }

        span {
            font-size: 18px;
            font-family: $lato;
            display: block;
        }

        svg {
            position: absolute;
            top: 0px;
            left: 150%;
            transform: rotate(180deg);

            circle {
                stroke: $navigation_grey;
                transition: 200ms all ease;
            }

            polyline {
                stroke: $navigation_grey;
                transition: 200ms all ease;
            }

            g {
                opacity: 1;
            }
        }

    }



    a {
        position: absolute;
        display: block;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: -100px;
        z-index: 100;
    }

    html.no-touch & {
        &:hover {
            svg {
                circle {
                    stroke: $blue;
                    fill: $blue;
                }

                polyline {
                    stroke: $white;
                }
            }

            cursor: pointer;
        }
    }

    &.up {
        svg {
            transform: rotate(90deg) !important;
        }
    }

    &.down {
        svg {
            transform: rotate(270deg);
        }
    }

    &.box-left {
        text-align: right;
        padding-right: 50px !important;

        svg {
            right: 150%;
            left: auto;
            transform: rotate(0deg);
        }

        a {
            left: -100px;
        }
    }

    &.box-left.up {
        svg {
            right: 150%;
            left: auto;
            transform: rotate(0deg);
        }
    }

    &.filled {
        svg {
            circle {
                stroke: $blue;
                fill: $blue;
            }

            polyline {
                stroke: $white;
            }

        }
    }
}