header#navigation {
    position: fixed;
    top: 0px;
    left: 0px;
    height: $navigation_height;
    width: 100%;
    background: $white;
    box-shadow: 0 2px 14px 0 rgba(200, 200, 200, 0.50);
    z-index: 1000;

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        background: transparent;
        box-shadow: 0 0px 0px 0 rgba(200, 200, 200, 0);
        z-index: -1;
    }

    .container {
        position: relative;

        .ui.menu {
            margin-top: 40px;
            visibility: hidden;

            .item {
                flex: 1 1 0;
                flex-direction: column;
                align-items: flex-start;
                margin: 0px 5px;
                font-family: $lato;
                pointer-events: none;

                &.active {
                    font-weight: bold;
                }

                .progress {
                    width: 100%;
                    margin-top: 15px;

                    background: rgba(45, 56, 68, 0.1);

                    .bar {
                        background: #969BA1;
                        transition: width .2s ease, background-color .2s ease;
                        min-width: 0px;
                    }
                }
            }

            body.survey & {
                visibility: visible;
            }
        }

        .logo {
            position: absolute;
            top: -36px;
            left: 0%;
            transform: translateX(0%);

            svg {
                height: auto;
                width: 100px;
            }

            @media screen and (max-width: 1600px) {
                top: -40px;
                left: 0px;
            }

            @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
                z-index: -1;
                left: auto;
                right: 0px;
                transform: translateZ(-1);
            }
        }
    }
}

body.survey {
    header#navigation {
        .logo {
            display: none;
        }

        @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
            background: $white !important;
            box-shadow: 0 2px 14px 0 rgba(200, 200, 200, 0.50);
            z-index: 999;
            height: 70px;
        }

        .ui.menu {
            margin-top: 20px;
        }
    }
}