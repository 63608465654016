body.intro {
    section#main {
        align-items: normal;
        padding: $navigation_height + 50px 100px;
        display: block;

        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            padding-top: 50px;
        }
    }

    .content-wrapper {
        width: 60%;
    }

    .ui.page.modals {
        background-color: rgba(0, 0, 0, 0.4);
    }
    .ui.modal {
        box-shadow: 10px 10px 0px $black;
        width: 550px;
        height: 350px;
        border: 2px solid $black;
        position: fixed;
        top: 50%;
        left: 50%;
        margin: -275px -175px;

        border-radius: 0px;
        .content {
            background: #f5fbfe;
            padding: 0px;
            height: 100%;
            header {
                background: $white;
                text-align: center;
                padding: 40px 0px;
                h2 {
                    font-size: 40px;
                }
                .close {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-size: 20px;
                }
            }
            section {
                padding: 40px 30px 0px;
                text-align: center;
                h3 {
                }
                p {
                }
            }
        }
    }

    .content {
        padding-right: 35px;

        h1 {
            margin-bottom: 30px;
            font-size: 40px;
            font-weight: 600;

            @media screen and (max-width: 992px) {
                font-size: 30px;
            }
        }

        p {
            margin-bottom: 20px;
        }

        ul {
            list-style-type: none;
            padding: 0px;

            li {
                margin-bottom: 8px;

                span:first-of-type {
                    border: 2px solid $black;
                    border-radius: 50%;
                    height: 28px;
                    width: 28px;
                    font-size: 14px;
                    font-weight: bold;
                    display: inline-block;
                    padding: 3px 0;
                    text-align: center;
                    margin-right: 15px;
                }

                span.dash {
                    display: inline-block;
                    height: 3px;
                    background: $black;
                    width: 15px;
                    margin-right: 15px;
                    transform: translateY(-2px);
                }
            }
        }
    }

    .action {
        margin-top: 30px;

        h3 {
            font-size: 24px;
            font-family: $lato;
        }

        svg {
            transform: rotate(180deg);

            g {
                opacity: 1;
            }

            circle {
                fill: $yellow;
                stroke: $yellow;
            }

            polyline {
                stroke: $white;
            }
        }

        .column:first-of-type {
            padding-right: 50px;
            padding-top: 3px;
        }
    }

    .background-svg {
        svg {
            position: fixed;
            bottom: 0px;
            right: 0px;
            width: 1920px;
            transform: translate(30%, 35%);
            z-index: -1;
        }

        img {
            position: fixed;
            bottom: 0px;
            right: 0px;
            width: 60%;
            z-index: -1;
        }
    }

    .dots {
        svg {
            position: absolute;
            top: 0px;
            transform: translate(-50px, 170px);
            z-index: -1;
        }
    }
}
